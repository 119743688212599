import en from './en';

type i18nKeys = keyof typeof en;
type i18nValues = (typeof en)[i18nKeys];

const de: Record<i18nKeys, i18nValues> = {
    account: {
        bids: {
            title: 'Ihre Angebote',
            emptyState: {
                base: 'Sie haben noch kein Gebot abgegeben.',
                cta: 'Nehmen Sie an Auktionen teil',
            },
            filters: {
                bidPosition: {
                    base: 'Adjudication',
                    anyOption: 'Alle Gebote',
                    maxBidder: 'Ihr Gebot ist das beste',
                    outbid: 'Überboten',
                },
                state: {
                    base: 'Status der Auktion',
                    anyOption: 'Alle Auktionen',
                    Closed: 'Abgeschlossene Auktionen',
                    Running: 'Laufende Auktionen',
                },
            },
        },
        sales: {
            title: 'Ihre Verkäufe',
            emptyStateText: 'Es gibt keine Auktionen mit einem Verkäufer, der mit diesem Konto verbunden ist.',
        },
    },
    common: {
        logout: 'Beenden Sie',
        login: 'Anmelden',
        loginSignup: 'Anmelden/Registrieren',
        loginTitle: 'Anmeldung bei Fleequid',
        profile: 'Dein Profil',
        searchPlaceholder: 'Suche...',
        searchNoResults: 'Keine Ergebnisse gefunden für"{query}"',
        email: 'E-Mail',
        yourAccount: 'Ihr Konto',
    },
    cookie: {
        name: 'Name',
        provider: 'Anbieter',
        purpose: 'Zweck',
        expiry: 'Verfallsdatum',
        type: 'Typ',
    },
    error: {
        '404': {
            title: '404',
            subtitle: 'Ups, Seite nicht gefunden',
            description: 'Sorry, die angeforderte Seite existiert nicht. Auf Fleequid gibt es aber noch viel mehr zu entdecken.',
            button: 'Zurück zur Startseite',
        },
        '500': {
            title: '500',
            subtitle: 'Oops! Ein Fehler ist aufgetreten',
            description: 'Wir entschuldigen uns für die Unannehmlichkeiten. Wir arbeiten bereits daran, das Problem zu beheben.',
            help: {
                base: 'Bitte versuchen Sie es in der Zwischenzeit später noch einmal oder {contactUs}',
                contactUs: 'kontaktieren Sie uns unter {email}, oder {phone} für Notfälle',
            },
            button: 'Zurück zur Homepage',
        },
        backend: {
            InvalidEnumValue: 'Ungültiger Datentyp',
            InvalidDate: 'Ungültiges Datumsformat',
            InvalidLength: 'Falsche Länge. Stellen Sie sicher, dass Sie nur die erforderlichen Zeichen eingegeben haben',
            NotAString: 'Ungültiger Typ; geben Sie Text ein',
            EmptyString: 'Fehlende Daten. Füllen Sie alle erforderlichen Informationen aus',
            PrismaError: 'Daten können nicht gespeichert werden; versuchen Sie einen anderen Wert',
            AuthAuthenticationVersionMismatch: 'Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an, um fortzufahren',
            AuthWrongCredentials: 'Ungültige Anmeldedaten',
            AuthNoUserByEmail: 'Es gibt kein Konto, das mit der eingegebenen E-Mail-Adresse verbunden ist.',
            AuthWrongPassword: 'Das eingegebene Passwort ist falsch.',
            UserIsBanned: 'Ihr Konto wurde gesperrt. Bitte kontaktieren Sie uns für weitere Informationen.',
            AuthEmailNotVerified:
                'Die von Ihnen eingegebene E-Mail-Adresse wurde noch nicht verifiziert. Wir haben einen neuen Link geschickt, um die E-Mail zu verifizieren.',
            SignupUserAlreadyExists:
                'Die E-Mail-Adresse ist bereits registriert. Wenn Sie Ihr Passwort vergessen haben, versuchen Sie bitte, es abzurufen.',
            UploadFileError: 'Fehler beim Laden der Datei',
            IsNotTrue: 'Fehlende Auswahl. Obligatorische Option ankreuzen',
            EntityNotFound: 'Keine Ergebnisse gefunden',
            PasswordResetEmailNotExists: 'Es gibt kein Konto, das mit dieser E-Mail verbunden ist',
            EmailChangeWrongPassword: 'Sie können die E-Mail-Adresse nicht ändern, weil das Passwort falsch ist',
            CantBidUserCompanyEqSellerCompany: 'Dieser Vermögenswert ist Eigentum Ihres Unternehmens, Sie können ihn nicht kaufen.',
            authentication: 'Authentifizierungsfehler; bitte versuchen Sie, sich erneut anzumelden',
        },
        default: {
            title: 'Fehler',
            subtitle: 'Ein unerwarteter Fehler ist aufgetreten',
            description: 'Wir arbeiten bereits daran, das Problem zu beheben.',
            button: 'Zurück zur Homepage',
        },
    },
    a11y: {
        auctionDetail: 'Erfahren Sie mehr über {name}',
    },
    adp: {
        imageGallery: 'Vollständige Galerie ({totalImages})',
        videoGallery: 'Videos ansehen ({totalVideos})',
        imagePagination: 'Fotos {0} von {1}',
        videoPagination: 'Video {0} von {1}',
        papers: 'Dokumente',
        registrationDocument: 'Zulassungsbescheinigung',
        cocDocument: 'C.O.C.',
        maintenanceCard: 'Wartungshistorie',
        detailsTitle: 'Details zum Fahrzeug',
    },
    asset: {
        type: {
            Bus: 'Bus',
        },
        class: {
            base: 'Klasse',
            ClassOne: 'Urban',
            ClassTwo: 'Interurban',
            ClassThree: 'Touristisch',
            ClassA: 'Klasse A',
            ClassB: 'Klasse B',
            SchoolBus: 'Schulbus',
        },
        kilometers: 'Kilometerstand',
        firstRegistration: 'Anmeldung',
        antiPollution: 'Anti-Verschmutzungsvorschriften',
        euroNorm: {
            Euro0: 'Euro 0',
            Euro1: 'Euro 1',
            Euro2: 'Euro 2',
            Euro3: 'Euro 3',
            Euro4: 'Euro 4',
            Euro5: 'Euro 5',
            Euro5Eev: 'Euro 5 EEV',
            Euro6: 'Euro 6',
            Euro6a: 'Euro 6a',
            Euro6c: 'Euro 6c',
            Euro6dTemp: 'Euro 6d-TEMP',
            Euro6d: 'Euro 6d',
        },
        toBeScraped: 'Zu verschrotten',
        expiredInspection: 'Überholung verfallen',
        additionalNotes: 'Fahrzeugspezifikation',
        description: 'Beschreibung',
        version: 'Version',
        geographicalLocation: 'Standort',
        vehicleCondition: {
            base: 'Fahrzeugzustand',
            Blocked: 'Läuft nicht',
            Rollable: 'Abschleppbar',
            Running: 'Marschierend',
        },
        length: 'Länge',
        height: 'Höhe',
        width: 'Breite',
        vin: 'Rahmen',
        converter: 'Ausrüster',
        visitDate: {
            base: 'Einsehbar',
            fromTo: 'am {date} von {fromTime} bis {toTime}',
        },
        inspectionExpiryDate: 'Fälligkeitsdatum der Überprüfung',
        doors: {
            base: 'Türen',
            Double: '2',
            DoubleDouble: '2+2',
            DoubleDoubleDouble: '2+2+2',
            DoubleDoubleSingle: '2+2+1',
            DoubleSingleSingle: '2+1+1',
            Single: '1',
            SingleDouble: '1+2',
            SingleSingle: '1+1',
            SingleDoubleSingle: '1+2+1',
            SingleSingleSingle: '1+1+1',
        },
        seatUpholstery: {
            base: 'Sitzbezug',
            Fabric: 'Stoff',
            Leather: 'Leder',
            Plastic: 'Kunststoff',
            FabricLeather: 'Leder/Stoff',
        },
        totalCapacity: 'Gesamtzahl der Sitze',
        seats: 'Sitze',
        seatsFromS1: 'Sitze inkl. Fahrtenbuch (S1)',
        standingPlaces: 'Stehplätze',
        standingPlacesFromS2: 'Stehplätze lt. Führerschein (S2)',
        wheelchairPlaces: 'Rollstuhlplätze',
        companionSeats: 'Plätze für Begleitpersonen',
        wheelchairRamp: {
            base: 'Behindertenrampe Typ',
            Absent: 'Abwesend',
            Electric: 'Elektrisch',
            Manual: 'Manuell',
            Electropneumatic: 'Elektro-pneumatisch',
        },
        luggageCapacity: 'Fassungsvermögen des Gepäckraums',
        passThroughLuggageCompartment: 'Durchgehender Gepäckraum',
        hatBox: 'Hutablage',
        transmission: 'Getriebe',
        gearboxType: {
            base: 'Getriebetyp',
            Auto: 'Automatik',
            Manual: 'Manuell',
            SemiAuto: 'Halbautomatisch',
        },
        gearboxBrand: 'Getriebemodell',
        gearboxModel: 'Modell des Getriebes',
        glasses: {
            base: 'Brillen',
            Single: 'Einzelpersonen',
            Double: 'Doppeltes',
        },
        openableWindows: 'Öffnende Fenster',
        engine: 'Motor',
        engineBrand: 'Motor Marke',
        engineModel: 'Motormodell',
        hp: 'Pferdestärken',
        kw: 'Leistung',
        displacement: 'Hubraum',
        cylinders: 'Anzahl von Zylindern',
        fuel: {
            base: 'Einspeisung',
            CNG: 'CNG',
            Electric: 'Elektrizität',
            GPL: 'LPG',
            Diesel: 'Diesel',
            Gasoline: 'Benzin',
            Hybrid: 'Hybrid',
            Hydrogen: 'Wasserstoff',
        },
        fuelTankCapacity: 'Tankinhalt',
        gasTanksCapacity: 'Gastanks',
        gasTanksMaterial: {
            base: 'Material des Tanks',
            Aluminum: 'Aluminium',
            Composite: 'Verbundwerkstoff',
            Steel: 'Stahl',
        },
        batteryPower: 'Kapazität der Batterie',
        chargingType: 'Art der Aufladung',
        axlesTires: 'Achsen und Reifen',
        wheelSize: 'Reifendimension',
        wheels: 'Anzahl der Reifen (doppelt oder einfach)',
        axles: 'Achsen',
        wheelsSizeAxle1: 'Radgröße Achse 1',
        wheelsSizeAxle2: 'Radgröße Achse 2',
        wheelsSizeAxle3: 'Radgröße, Achse 3',
        alloyWheels: 'Leichtmetallräder',
        spareWheel: 'Ersatzrad',
        firePreventionSystem: 'Feuerlöschanlage',
        firePreventionSystemBrand: 'Feuerlöschsystem Marke',
        abs: 'ABS',
        asr: 'ASR',
        retarder: 'Retarder',
        breathalyzer: 'Beatmungsgerät',
        tachograph: 'Fahrtenschreiber',
        indicatorSigns: 'Anzeigetafeln',
        indicatorSignsBrand: 'Hersteller der Anzeigetafeln',
        accessories: 'Zubehör',
        cruiseControl: {
            base: 'Geschwindigkeitsregler',
            No: 'Nein',
            Adaptive: 'Adaptiv',
            Standard: 'Standard',
        },
        deckType: {
            base: 'Flachbett',
            LowEntry: 'Niedriger Eintrag',
            LowFloor: 'Niederflur',
            NormalFloor: 'Normaler Boden',
        },
        acDriver: 'Klimatisierung Fahrer',
        driverBerth: 'Fahrer-Koje',
        wifi: 'Wi-Fi',
        cctv: 'Videoüberwachung (CCTV)',
        internalCameras: 'Interne Kameras',
        externalCameras: 'Externe Kameras',
        retrocamera: 'Rückfahrkamera',
        microphone: 'Mikrofon',
        cdPlayer: 'CD-Spieler',
        dvdPlayer: 'DVD-Spieler',
        usbPlayer: 'USB-Radioplayer',
        heater: 'Heizung',
        preHeater: 'Vorwärmer',
        kitchen: 'Küche',
        coffeeMachine: 'Kaffeemaschine',
        refrigerator: 'Kühlschrank',
        toilet: 'Toilette',
        safetyBelts: 'Sicherheitsgurte',
        acPassengers: 'Beifahrer-Klimaanlage',
        curtains: 'Sonnenrollos',
        seatPocket: 'Stauraumnetz',
        footRest: 'Fußstütze',
        handRest: 'Armlehnen',
        seatsMonitor: 'Sitzmonitore',
        seatsRadio: 'Sitzradio',
        seatsUsbPorts: 'USB zu den Sitzen',
        socket220: '220-V-Steckdose für Fahrer',
        seatsSocket220: '220-V-Steckdosen an den Sitzen',
        extendableSeats: 'Ausziehbare Sitze',
        recliningSeats: 'Verstellbare Sitze',
        coffeeTables: 'Sitztische',
    },
    auction: {
        countdown: {
            startsIn: 'Beginnt zwischen',
            endsIn: 'Endet zwischen',
            days: 'g',
            hours: 'h',
            minutes: 'm',
            seconds: 's',
        },
        bail: {
            title: 'Bindet die Kaution zur Teilnahme an der Auktion',
            description: 'Um an dieser Auktion teilnehmen zu können, müssen Sie Ihre Kaution mit einer gültigen Zahlungsmethode hinterlegen.',
            amount: 'KAUTION',
            note: 'Die Bietkaution wird am Ende der Auktion wieder freigegeben, wenn Sie nicht zu den Höchstbietenden gehören.',
            subnote: 'Die Kautionen sind nicht für alle Objekte gleich und können je nach Auktionsbasis variieren',
            error: 'Bei der Bearbeitung Ihrer Transaktion ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal oder wenden Sie sich an den Support',
            submit: 'Kaution binden',
        },
        bid: {
            title: 'Jetzt Höchstbietender werden',
            description:
                'Denken Sie daran, dass Ihr Gebot verbindlich ist und Sie sich verpflichten, es zu bezahlen, wenn Ihr Gebot nicht übertroffen wird.',
            yourNewOffer: 'Ihr neues Angebot',
            confirm: 'Angebot jetzt bestätigen',
            cancel: 'Abbrechen',
            success: 'Angebot angenommen!',
            total: 'Gesamt',
            outbidError: {
                title: 'Ein anderer Benutzer überbietet Sie',
                description:
                    'Das Angebot, das Sie ausgewählt haben, wurde bereits von einem anderen Nutzer überboten. Bitte schließen Sie Ihr Angebot und aktualisieren Sie es.',
                close: 'Schließen',
            },
            expiredError: {
                title: 'Die Auktion ist beendet',
                description: 'Es können keine Gebote mehr abgegeben werden.',
            },
        },
        bidButton: 'Geben Sie Ihr Gebot ab',
        auctionPanel: {
            reservePrice: 'Reserve-Preis',
            startingPrice: 'Startgebot',
            current: 'Aktuell',
            wonAuction: 'Auktion gewonnen!',
            state: {
                Closed: 'Auktion beendet',
                Canceled: 'Auktion abgebrochen',
                Draft: 'Entwurf der Auktion',
            },
            maxBidder: 'Ihr Gebot ist das beste!',
            outBid: 'Überboten!',
            negotiationInProgress: 'Verhandlung im Gange',
            feeNotice: '+ Provision ({0})',
            feeNoticeLink: 'was sind sie?',
            priceNotAvailable: 'N/A',
            bidNotice: {
                reservePriceNotReached: {
                    base: 'Reservepreis {0}',
                    notReached: 'noch nicht erreicht',
                },
                notScrapyard:
                    'Auktion reserviert für Abschleppunternehmen, die im Abfallwirtschaftsregister in der Kategorie 5 eingetragen sind, und Betreiber des C.E.R.-Codes 16.01.04',
                sameCompany: {
                    Running: 'Das Fahrzeug gehört zu Ihrem Unternehmen',
                    base: 'Das Fahrzeug gehört zu Ihrem Unternehmen',
                },
                isAdmin: 'Sie können nicht teilnehmen, weil Ihr Benutzer die Rolle Admin hat',
            },
        },
        needSupport: 'Brauchen Sie Unterstützung?',
        contactUs: 'Kontaktieren Sie den Fleequid-Kundenservice',
    },
    auctions: {
        page: {
            asset: {
                Bus: 'busse',
            },
            title: 'Entdecken Sie alle {title} auf Fleequid',
            results: '{total} sichtbare Auktionen',
            emptyStateTitle: 'Keine Ergebnisse',
            emptyStateText: 'Angewandte Filter ergaben keine Ergebnisse.',
            resetFilters: 'Filter entfernen',
        },
        filters: {
            applied: 'Angewandte Filter',
            button: {
                label: 'Filterstangen',
            },
            modal: {
                title: 'Filter Auktionen',
                back: 'Zurück zu den Ergebnissen',
            },
            label: 'Filter',
            class: 'Klasse',
            brand: 'Marke, Modell, Version',
            brandFilter: 'Marke',
            brandFilterAnyOption: 'Alle Marken',
            modelFilter: 'Modell',
            modelFilterAnyOption: 'Alle Modelle',
            versionFilter: 'Version',
            versionFilterAnyOption: 'Alle Versionen',
            antiPollution: 'Anti-Verschmutzungsvorschriften',
            registration: 'Registrierung',
            firstRegistrationYearFrom: 'Ab (Jahr)',
            firstRegistrationYearTo: 'Bis zu (Jahr)',
            firstRegistrationYearAnyOption: 'Jedes Jahr',
            kilometers: 'Kilometerstand',
            kmFrom: 'Stand von',
            kmTo: 'Bis zu',
            kmAnyOption: 'Jeder Kilometerstand',
            scrapping: {
                title: 'Einschränkungen für den Abriss',
                help: 'Was sind Abrissrestriktionen?',
                all: 'Alle Fahrzeuge',
                requireScrapping: 'Mit Verschrottungspflicht',
                doNotRequireScrapping: 'Ohne Verschrottungspflicht',
            },
            fuel: 'Fütterung',
            empty: 'Kein Filter angewendet.',
        },
        appliedFilters: {
            firstRegistrationYearFrom: 'von: {year}',
            firstRegistrationYearTo: 'a: {year}',
            kmFrom: 'von: {km}',
            kmTo: 'a: {km}',
            reset: 'Filter entfernen',
            appliedFiltersCounter: '{count} aktive Filter | {count} aktive Filter',
        },
    },
    homepage: {
        link: 'Home',
        hero: {
            title: 'There are no heavy vehicles',
            subtitle: 'Join our online auctions and buy or sell your bus in a simple, fast and transparent way',
            buttonLabel: 'Participate in auctions',
        },
        whatIsFleequid: {
            title: 'What is Fleequid?',
            description: 'Fleequid is the new way to sell and buy vehicles at auction for your business',
            section: {
                simplicity: {
                    title: 'Simplicity',
                    description: 'A linear and intuitive process, which eliminates endless negotiations',
                },
                speed: {
                    title: 'Speed',
                    description: 'You can buy your new bus in 7 days',
                },
                transparency: {
                    title: 'Transparency',
                    description: 'Clear prices, detailed images, dedicated support',
                },
            },
        },
        vision: {
            caption: 'Our vision',
            title: 'We believe in the benefits of used vehicles. We want to help companies manage the buying and selling of company vehicles, saving time and resources, turning a problem into an opportunity.',
            highlight: 'Forget risks and uncertainties: with us, giving value to your assets becomes simple.',
        },
        howItWorks: {
            title: 'How it works',
            description: 'We strive to keep our platform simple and intuitive so you can easily manage your bus fleet',
            signUp: {
                title: 'Sign up for Fleequid',
                create: 'Create a {account} using your company details',
                account: 'new account',
                verify: 'Verify your details and {browse} the site',
                browse: 'start browsing',
            },
            join: {
                title: 'Participate in auctions',
                choose: 'Choose a {bus} from the list',
                bus: 'bus',
                add: 'Add a valid {payment} to start bidding',
                payment: 'payment method',
                follow: 'Follow the auction and {win}',
                win: 'win your favorite buses',
            },
            sell: {
                title: 'Sell your company buses',
                letUsKnow: 'Let us know all the details of the {bus}',
                bus: 'bus for sale',
                choose: 'Choose a {price} for your bus',
                price: 'minimum price', // TODO
                wait: 'Wait for the auction to end to {confirm}',
                confirm: 'confirm the sale',
            },
        },
        whyFleequid: {
            title: 'Why choose Fleequid?',
            description: 'We want to become your trusted partner in managing your fleet',
            section: {
                euReach: {
                    title: 'European reach',
                    description: 'Save time by avoiding undecided people and simplifying administrative and documentary procedures.',
                },
                unifiedBilling: {
                    title: 'Unified billing',
                    description: 'Enjoy the convenience of having an efficient platform and a single company name to invoice.',
                },
                easyAuctions: {
                    title: 'Easy and convenient auctions',
                    description: "Thanks to our pre- and post-purchase support service, participating in auctions will be child's play.",
                },
                operationalEfficiency: {
                    title: 'Operational efficiency',
                    description: 'Save time by avoiding the indecisive and simplifying paperwork.',
                },
                sell: {
                    title: 'Sell your vehicles',
                    description: 'Fleequid supports you in setting the sales price and creating the photo dossier.',
                    cta: 'Sell on Fleequid',
                },
            },
        },
        startToday: {
            title: 'Start today on Fleequid',
            description: 'View all available auctions and get ready for the best products',
            cta: 'Participate in auctions',
        },
    },
    faq: {
        title: 'Frequently asked questions',
        start: {
            base: 'How to get started',
            participate: {
                q: 'How can I participate in an online auction?',
                a: 'To join online auctions on Fleequid you need to sign up at {0} providing your business information and sending the required documents. Once you’ve done that, you can check out the pages with the ongoing auctions, and place one or more bids to compete for the purchase of a vehicle. Auctions are held electronically, and you can place bids until the auction closes. The auction ends when the user who submitted the highest bid awards the vehicle, as long as the bid is at least the reserve price, or if the seller accepts a bid that is lower than the reserve price. ',
                link: 'https://fleequid.com/en/signup',
            },
            reservePrice: {
                q: 'What is the reserve price?',
                a: 'The reserve price is the minimum amount that must be exceeded for the auction to be successful. If this amount is not reached during the auction, it’s up to the seller to decide whether they are willing to accept the best offer they received anyway. The reserve price is a higher amount than the starting bid: it is set by the seller before the start of the auction and will not be visible to the public, who only knows the highest bid made up to that moment.',
            },
            countries: {
                q: 'In which countries is it possible to buy and sell buses through Fleequid?',
                a: 'Fleequid operates in Italy, in the European Union and in several non-EU countries, complying with the regulations of each nation. If you have any doubts about the possibility of using Fleequid from your country, please contact our customer service at {0}.',
            },
            bureaucracy: {
                q: 'Who is responsible for property transfers, vehicle registration and every documentation aspect?',
                a: "Fleequid will communicate to seller and buyer how to handle documentation aspects and payments related to the property transfer, depending on the specifics of the deal (between Italian companies, EU, and non-EU) and on the platform's role in the sales process. However, registration of the vehicle in the country of destination is the responsibility of the buyer.",
            },
            toBeScraped: {
                q: 'What are vehicles intended for scrapping?',
                a: 'Vehicles intended for scrapping are those which, being no longer suitable for circulation and the transportation of people, must necessarily, according to current laws or regulation rules, undergo a recovery and partial reuse process of their components as spare parts. Participation in auctions for these vehicles is only permitted for dismantlers listed in the waste management register in category 5 and managers of the C.E.R. code 16.01.04. At the moment, the auctions for vehicles intended for scrapping are only available in Italy.',
            },
            vies: {
                q: 'What is Vies? Why is it mandatory to be included?',
                a: 'It’s a search engine (not a database) created by the European Commission to facilitate sales between companies in UE. It’s an electronic mean of validating VAT-identification numbers of economic operators registered in the European Union for cross border transactions on goods or services. For this reason, European companies that want to be on Fleequid must necessarily be present there.',
            },
        },
        sell: {
            base: 'How to sell',
            selling: {
                q: 'How can I start selling on Fleequid?',
                a: 'If you have a bus to sell, please contact our customer service by writing to {0} or fill out the form found at {1}, providing information about the vehicle you want to sell.  You also need to register your company as a seller on the platform.',
            },

            startingPrice: {
                q: 'How is the starting price defined?',
                a: 'The starting price is the minimum price for the vehicle above which potential buyers can place their bids or make counteroffers on the Marketplace. The starting bid is calculated by Fleequid based on its technical assessment of the vehicle and must be lower than the reserve price, which is instead set by the seller.',
            },
            delivery: {
                q: 'How is the vehicle delivered?',
                a: 'After receiving payment from the buyer, the seller must enable the buyer to collect the vehicle; the buyer must promptly proceed with the pickup, which must take place within ten days from the date Fleequid informs the seller of the completion of the de-registration procedures in the country of origin of the bus. For each day of delay beyond the specified timeline, Fleequid will impose a penalty.',
            },
            howLong: {
                q: 'How long does it take for the bus to be sold?',
                a: 'When a vehicle is uploaded to the platform, Fleequid shares with the seller the start and end dates of the auction and the information about pre-auction visibility; these dates are established by Fleequid based on the specifics of the vehicle to be sold. If the sale did not conclude successfully, Fleequid would still have the right to hold up to 3 consecutive auctions within a total of 30 days.',
            },
        },
        buy: {
            base: 'How to buy',
            inspection: {
                q: 'Is it possible to inspect vehicles before bidding?',
                a: "The seller can decide whether to offer the option of live inspection of the vehicle. If allowed, the seller will define a specific day for live visits and inspections of the vehicle, directly at the seller's location.",
            },
            claims: {
                q: 'How do you handle hidden defects and complaints?',
                a: 'Fleequid acts as an intermediary between seller and buyer and, as such, is not responsible for any faults, whether hidden or visible, defects, wear and tear, irregularities, unsuitability for use, as well as errors or inaccuracies in the declarations made by the seller. By posting their auction on Fleequid, the seller commits to transparency and to providing accurate information about the condition of the vehicle; at the same time, the buyer must commit to reporting any discrepancies in a timely manner (3 days for apparent defects) within one month from the pick-up for hidden defects of the vehicle. Fleequid is available to help buyers manage complaints and provides s-pecific conditions for their handling.',
            },
            paymentMethods: {
                q: 'What payment methods are accepted?',
                a: 'Fleequid accepts bank transfers and credit card payments. More specifically, the security deposit must be paid only by credit card; the purchase of the vehicle, the payment of commissions and any additional costs must be done via bank transfer. ',
            },
            howPaymentWorks: {
                q: 'How does payment work?',
                a: 'The user who wins the auction is required to pay an amount that includes the winning price, the selling fee for Fleequid and any other agreed-upon fees (see General Conditions for more details). The buyer must pay the amount due by bank transfer no later than three days after receiving the corresponding invoice. Partial payments are not allowed.',
            },
            deposit: {
                q: 'How does the security deposit work?',
                a: 'The security deposit is the sum of money that the user gives to Fleequid when placing a bid in the auction, as a guarantee of the seriousness of the offer itself. When the auction ends all deposits are released, except those of the first and second place winners. If the winner finalizes the purchase of the vehicle, by making the bank transfer for the agreed amount (no later than 3 days after receiving the corresponding invoice), both their deposit and that of the second bidder will be released. If the winner fails to make payment within the agreed time, they will lose both the bus and the security deposit. In this case, the second-place winner will automatically become the new winner of the auction and, by purchasing the vehicle by bank transfer, the security deposit will be returned.',
            },
        },
        contactUs:
            'We hope you have found all the answers to your questions on this page, but if not, we are always happy to help. Call us on {0}, or contact us by writing to {1}.',
    },
    footer: {
        cookiePolicy: {
            base: 'Cookie-Richtlinie',
            changeConsent: 'Ändern Sie Ihre Zustimmung',
            revokeConsent: 'Widerrufen Sie Ihre Zustimmung',
        },
        explore: 'Navigieren Sie',
        legal: 'Rechtliche Informationen',
        support: 'Brauchen Sie Unterstützung?',
        vat: 'MWST.-NUMMER',
        backTop: 'Zurück zum Anfang',
    },
    menu: {
        buy: 'Kaufen',
        sell: 'Verkaufen',
        faq: 'F.A.Q.',
    },
    mktg: {
        whyFleequid: {
            title: 'Warum Fleequid?',
            deal: {
                title: 'Vereinfachtes Kaufen und Verkaufen',
                description: 'Eine Technologieplattform anstelle von Verhandlungen mit unentschlossenen Parteien',
            },
            processes: {
                title: 'Schnelle Prozesse',
                description: 'Fleequid reduziert Zeit und spart Ihren Mitarbeitern Energie',
            },
            support: {
                title: 'Transparente Unterstützung',
                description: 'Alles ist klar, von der Registrierung bis zur Lieferung',
            },
        },
    },
    notifications: {
        success: 'Erfolg',
        error: 'Fehler',
        genericError: 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.',
        tryAgain: 'Bitte versuchen Sie es erneut',
        userOutBid: {
            base: 'Ihr Gebot von {prevBidAmount} für {subject} wurde überschritten, der neue aktuelle Preis ist {currentBidAmount}',
            inAuction: 'diese Auktion',
            linkedAuction: '{brand} {model} {version}',
        },
        userBailAuthorized: {
            inAuction: 'Sie sind zur Teilnahme an dieser Auktion autorisiert worden',
            linkedAuction: 'Sie sind berechtigt, an dieser Auktion teilzunehmen {brand} {model}',
        },
        userMaxBidderRestored: {
            base: 'Ihr Gebot von {prevBidAmount} für {subject} ist das beste!',
            inAuction: 'diese Auktion',
            linkedAuction: '{brand} {model} {version}',
        },
    },
    privacy: {
        link: 'Datenschutzbestimmungen',
    },
    seo: {
        auction: {
            title: '{asset} {brand} {model} {version} {vin}',
            description:
                'Kaufen {brand} {model} {version}, {asset} gebraucht auf Fleequid.com: Nehmen Sie an der B2B-Auktion von und bieten Sie auf {asset} {vin} Marke {brand}, Modell {model} und Version {version}.',
        },
        auctions: {
            title: 'gebrauchte {0} zu verkaufen',
            description:
                'Kaufen Sie gebrauchte {0} online auf Fleequid.com : nehmen Sie einfach an B2B-Auktionen der besten gebrauchten {1} zum Verkauf teil.',
        },
        cookiePolicy: {
            title: 'Cookie-Politik',
            description:
                'Cookie-Richtlinie für das Fleequid-Portal: Lesen Sie, wie wir die Cookies verwalten, die wir verwenden, um das Surfen bequem zu machen.',
        },
        homepage: {
            title: 'Gebrauchte Busse zu verkaufen',
            description:
                'Kaufen oder verkaufen Sie Busse und Nutzfahrzeuge auf Auktionen: Werden Sie Mitglied bei Fleequid und nehmen Sie ganz einfach an B2B-Auktionen der besten Nutzfahrzeuge teil.',
        },
        privacyPolicy: {
            title: 'Datenschutzbestimmungen',
            description:
                'Datenschutzerklärung für das Fleequid-Portal: Wir erklären klar und deutlich, wie wir mit den Navigationsdaten und allen Ihren Daten umgehen.',
        },
        signup: {
            title: 'Fahrzeug-Auktionen: Registrierung',
            description:
                'Registrieren Sie sich jetzt bei Fleequid und nehmen Sie ganz einfach und bequem an Auktionen von neuen und gebrauchten Nutzfahrzeugen, Bussen und Reisebussen teil.',
            confirm: {
                title: 'E-Mail-Verifizierung',
            },
        },
        termsAndConditions: {
            title: 'Bedingungen und Konditionen',
            description:
                'Allgemeine Geschäftsbedingungen für den Verkauf und Kauf auf Fleequid, dem Online-Auktions- und Kaufportal für Industriefahrzeuge und Busse.',
        },
        pricingSeller: {
            title: 'Verkäufer-Provisionen',
            description: 'Auktionsgebühren auf Fleequid.com: Preisangaben und Angaben zur Art der Erhebung von Gebühren für Verkaufsauktionen.',
        },
        pricingBuyer: {
            title: 'Provisionen für Käufer',
            description:
                'Auf Fleequid.com erhobene Auktionsgebühren: Preisangaben und Angaben zur Art der Erhebung von Gebühren für Verkaufsauktionen.',
        },
    },
    signup: {
        link: 'Registrieren Sie sich unter',
        companyRegistration: 'Registrieren Sie Ihr Unternehmen',
        subtitle: 'Geben Sie Ihre Unternehmensdaten ein und betreten Sie eine neue Welt der Angebote.',
        companyInfo: 'Daten des Unternehmens',
        companyDocuments: 'Dokumente des Unternehmens',
        contactPerson: 'Kontaktinformationen des Unternehmens',
        accessCredentials: 'Zugangsberechtigung',
        fleetHandling: 'Flottenbewegung',
        fields: {
            address: 'Adresse',
            postCode: 'POSTCODE',
            city: 'Stadt',
            province: 'Bundesland',
            businessName: 'Name des Unternehmens',
            country: 'Land',
            pec: 'PEC',
            sdi: 'SDI-Code',
            vatId: 'VAT/VAT ID',
            companyRegistrationReportFile: 'Visum der Handelskammer',
            firstname: 'Name',
            lastname: 'Nachname',
            phone: 'Telefon-Nummer',
            type: {
                label: 'Typ des Betreibers',
                Dealer: 'Gewerbetreibender',
                PeopleTransportOperator: 'Personenbeförderungsunternehmen',
                Scrapyard: 'Abschleppunternehmen',
                Other: 'Sonstiges',
            },
            email: 'E-Mail',
            password: 'Kennwort',
            confirmPassword: 'Passwort-Bestätigung',
            stats: {
                sales: 'Wie viele Busse planen Sie in {year} zu verkaufen?',
                purchase: 'Wie viele Busse wollen Sie in {year} kaufen?',
                unknown: 'Ich weiß es nicht',
            },
            consent: 'Konsens',
            termsConditions: 'Ich habe die {0} des Dienstes gelesen und akzeptiere sie.',
            vexatiousClauses: 'Ich habe die folgenden Klauseln gelesen und akzeptiere sie:',
            privacy: 'Ich habe gelesen und akzeptiere die {0}.',
        },
        hint: {
            phone: {
                text: 'Zahlenformat',
                rule: 'Bitte geben Sie die Rufnummer im Format +39 1234567890 ein',
            },
        },
        validations: {
            requiredSDIOrPec: 'Geben Sie mindestens einen SDI-Code oder eine PEC-Adresse ein',
            companyRegistration: 'Die ausgewählte Datei ist zu groß. Die maximal zulässige Größe beträgt {size} MB',
        },
        success: {
            title: 'Vielen Dank, dass Sie die Registrierung abgeschlossen haben!',
            p1: 'Ihre Daten wurden erfolgreich empfangen.',
            p2: 'Sie werden in Kürze eine Bestätigungs-E-Mail erhalten, die einen Link enthält, auf den Sie klicken müssen, um Ihre E-Mail-Adresse zu verifizieren.',
            p3: 'Bitte überprüfen Sie auch den Spam-Ordner Ihrer Mailbox, da die E-Mail aufgrund Ihrer Einstellungen möglicherweise als Junk-Mail markiert wurde.',
            p4: 'Wir laden Sie ein, sich die Online-Auktionen anzusehen und herauszufinden, ob Fahrzeuge dabei sind, die für Sie interessant sind. Möchten Sie Ihre Busse bei der nächsten Auktion verkaufen? Kontaktieren Sie uns unter {0}.',
            p5: 'Danke, dass Sie uns gewählt haben!',
        },
        confirm: {
            success: {
                title: 'Ihre E-Mail wurde bestätigt!',
                p1: 'Vielen Dank, dass Sie Ihre {email} E-Mail bestätigt haben.',
                p2: 'Ihr Konto ist jetzt aktiv und Sie können auf alle Funktionen unseres Dienstes zugreifen.',
                p3: 'Wenn Sie Fragen haben oder Hilfe benötigen, wenden Sie sich bitte an unser Support-Team unter {0}. Willkommen an Bord!',
            },
            error: {
                title: 'E-Mail-Verifizierung fehlgeschlagen',
                p1: 'Es tut uns leid, aber die Überprüfung Ihrer E-Mail war nicht erfolgreich.',
                p2: 'Möglicherweise gab es ein Problem mit dem Bestätigungslink.',
                p3: 'Bitte versuchen Sie es erneut, indem Sie auf den Bestätigungslink klicken, den wir Ihnen geschickt haben. Sollte das Problem weiterhin bestehen, wenden Sie sich bitte an unser Support-Team unter {0}.',
            },
            buyCta: 'An Auktionen teilnehmen',
            hpCta: 'Zurück zur Homepage',
        },
    },
    termsConditions: {
        link: 'Bedingungen und Konditionen',
    },
    login: {
        email: 'E-Mail',
        password: 'Kennwort',
        formSubtitle: 'Geben Sie Ihre Fleequid-Anmeldedaten ein, um an Auktionen teilzunehmen',
        forgotPassword: 'Haben Sie Ihr Passwort vergessen?',
        or: 'Oder',
        unregistered: 'Sie haben noch kein Fleequid-Konto?',
        signupNow: 'Jetzt registrieren',
    },
    passwordReset: {
        request: {
            title: 'Passwort zurücksetzen',
            subTitle: 'Bitte geben Sie Ihre E-Mail-Adresse ein und wir senden Ihnen eine Nachricht, um Ihr Passwort zurückzusetzen.',
            submitButton: 'E-Mail senden',
            onSuccessTitle: 'Anfrage akzeptiert',
            onSuccessDescription: 'Sie erhalten eine E-Mail mit dem Verfahren zum Zurücksetzen des Passworts.',
        },
        reset: {
            title: 'Passwort zurücksetzen',
            description: 'Bitte geben Sie ein neues Passwort ein. Sobald die Änderung bestätigt ist, werden alle aktiven Sitzungen ungültig.',
            newPassword: 'Neues Passwort',
            confirmNewPassword: 'Bestätigen Sie das neue Passwort',
            submitButton: 'Passwort ändern',
            updateSuccess: 'Passwort erfolgreich geändert',
            tokenExpired: 'Das Passwort-Reset-Token ist abgelaufen.',
            tokenInvalid: 'Das Passwort-Reset-Token ist ungültig.',
            startOverDescription: 'Führen Sie die Passwort-Rücksetzungsanforderung erneut durch, dabei wird ein neues Token generiert.',
            startOverButton: 'Erneut versuchen',
        },
    },
    validation: {
        msg: {
            characters: 'Ein oder mehrere ungültige Zeichen',
            email: "Die E-Mail muss das Format user{'@'}domain.ext haben",
            incomplete: '{field} unvollständig',
            invalid: '{field} ungültig',
            invalidBid: 'Der eingegebene Betrag ist ungültig',
            missing: '{field} fehlt',
            max: 'Maximale Länge {count} Zeichen | Maximale Länge {count} Zeichen',
            min: 'Mindestlänge {count} Zeichen | Mindestlänge {count} Zeichen',
            length: 'Länge muss genau {count} Zeichen betragen | Länge muss genau {count} Zeichen betragen',
            passwordWeak: 'Passwort zu schwach',
            passwordMatch: 'Passwörter stimmen nicht überein',
            phone: 'Geben Sie nur Zahlen, Leerzeichen und das Zeichen + ein',
            recaptcha: 'ReCAPTCHA-Überprüfungsfehler',
            required: '{field} obligatorisch',
        },
        hint: {
            password: {
                text: 'Regeln für ein effektives Passwort',
                rules: {
                    length: 'Mindestens 8 Zeichen',
                    uppercase: '1 Großbuchstabe',
                    lowercase: '1 Kleinbuchstabe',
                    symbol: '1 Sonderzeichen',
                    number: '1 Zahl',
                },
            },
        },
    },
    profile: {
        title: 'Ihr Profil',
        companyData: {
            readOnlyDisclaimer: 'Die Unternehmensdaten sind schreibgeschützt. Wenn Sie eine Änderung wünschen, kontaktieren Sie uns bitte unter {0}.',
        },
        userData: {
            saveChanges: 'Bestätigen Sie die neuen Daten',
            cancelChanges: 'Änderungen abbrechen',
            updateSuccess: 'Firmenkontaktdaten erfolgreich gespeichert',
        },
        changePassword: {
            title: 'Passwort ändern',
            description: 'Wenn Sie das Passwort ändern, werden alle anderen aktiven Sitzungen außer der aktuellen ungültig.',
            currentPassword: 'Aktuelles Passwort',
            newPassword: 'Neues Passwort',
            confirmNewPassword: 'Bestätigen Sie das neue Passwort',
            submitButton: 'Bestätigen Sie das neue Passwort',
            updateSuccess: 'Passwort erfolgreich geändert',
        },
        changeEmail: {
            title: 'E-Mail ändern',
            description:
                'Sie erhalten eine E-Mail mit einem Link zur Bestätigung der neuen E-Mail-Adresse. Wenn Sie Ihre E-Mail ändern, werden alle aktiven Sitzungen ungültig.',
            password: 'Aktuelles Passwort',
            email: 'E-Mail',
            submitButton: 'Bestätigen Sie die neue E-Mail',
            updateSuccess: 'E-Mail erfolgreich geändert',
        },
    },
    sessionExpired: {
        title: 'Ihre Sitzung ist abgelaufen',
        description:
            'Ihre Sitzung wurde für ungültig erklärt, da Ihre Anmeldedaten geändert wurden. Bitte melden Sie sich mit neuen Zugangsdaten an.',
        close: 'Weiter',
    },
};

export default de;
