import en from './en';

type i18nKeys = keyof typeof en;
type i18nValues = (typeof en)[i18nKeys];

const es: Record<i18nKeys, i18nValues> = {
    account: {
        bids: {
            title: 'Sus ofertas',
            emptyState: {
                base: 'Aún no has pujado.',
                cta: 'Participar en subastas',
            },
            filters: {
                bidPosition: {
                    base: 'Adjudicación',
                    anyOption: 'Todas las pujas',
                    maxBidder: 'Su oferta es la mejor',
                    outbid: 'Oferta superada',
                },
                state: {
                    base: 'Estado de la subasta',
                    anyOption: 'Todas las subastas',
                    Closed: 'Subastas completadas',
                    Running: 'Subastas en curso',
                },
            },
        },
        sales: {
            title: 'Sus ventas',
            emptyStateText: 'No hay subastas con un vendedor asociado a esta cuenta.',
        },
    },
    common: {
        logout: 'Salir de',
        login: 'Entrar',
        loginSignup: 'Entrar/Registrarse',
        loginTitle: 'Iniciar sesión en Fleequid',
        profile: 'Tu perfil',
        searchPlaceholder: 'Buscar...',
        searchNoResults: 'No se han encontrado resultados para"{query}"',
        email: 'Email',
        yourAccount: 'Tu cuenta',
    },
    cookie: {
        name: 'Nombre',
        provider: 'Proveedor',
        purpose: 'Propósito',
        expiry: 'Fecha de caducidad',
        type: 'Tipo',
    },
    error: {
        '404': {
            title: '404',
            subtitle: 'Página no encontrada',
            description: 'Lo sentimos, la página solicitada no existe. Sin embargo, encontrarás mucho más que explorar en Fleequid.',
            button: 'Volver a la página de inicio',
        },
        '500': {
            title: '500',
            subtitle: 'Se ha producido un error',
            description: 'Disculpe las molestias. Ya estamos trabajando para resolver el problema.',
            help: {
                base: 'Mientras tanto, por favor inténtelo de nuevo más tarde o {contactUs}',
                contactUs: 'póngase en contacto con nosotros en {email}, o {phone} para emergencias',
            },
            button: 'Volver a la página de inicio',
        },
        backend: {
            InvalidEnumValue: 'Tipo de datos no válidos',
            InvalidDate: 'Formato de fecha no válido',
            InvalidLength: 'Longitud incorrecta. Asegúrese de haber introducido sólo los caracteres necesarios',
            NotAString: 'Tipo no válido; introduzca texto',
            EmptyString: 'Faltan datos. Complete toda la información requerida',
            PrismaError: 'No se han podido guardar los datos; pruebe con otro valor',
            AuthAuthenticationVersionMismatch: 'Su sesión ha expirado. Vuelva a iniciar sesión para continuar',
            AuthWrongCredentials: 'Credenciales no válidas',
            AuthNoUserByEmail: 'No hay ninguna cuenta asociada a la dirección de correo electrónico introducida.',
            AuthWrongPassword: 'La contraseña introducida es incorrecta.',
            UserIsBanned: 'Su cuenta ha sido suspendida. Póngase en contacto con nosotros para obtener más información.',
            AuthEmailNotVerified:
                'La dirección de correo electrónico introducida aún no ha sido verificada. Hemos enviado un nuevo enlace para verificar el correo electrónico.',
            SignupUserAlreadyExists: 'La dirección de correo electrónico ya está registrada, si ha olvidado su contraseña intente recuperarla.',
            UploadFileError: 'Error al cargar el archivo',
            IsNotTrue: 'Falta selección. Marcar opción obligatoria',
            EntityNotFound: 'No se han encontrado resultados',
            PasswordResetEmailNotExists: 'No hay ninguna cuenta asociada a este correo electrónico',
            EmailChangeWrongPassword: 'No puede cambiar la dirección de correo electrónico porque la contraseña es incorrecta',
            CantBidUserCompanyEqSellerCompany: 'Este activo es propiedad de su empresa, no puede comprarlo.',
            authentication: 'Error de autenticación; por favor, intente acceder de nuevo',
        },
        default: {
            title: 'Error',
            subtitle: 'Se ha producido un error inesperado',
            description: 'Ya estamos trabajando para resolver el problema.',
            button: 'Volver a la página de inicio',
        },
    },
    a11y: {
        auctionDetail: 'Más información sobre {name}',
    },
    adp: {
        imageGallery: 'Galería completa ({totalImages})',
        videoGallery: 'Ver vídeos ({totalVideos})',
        imagePagination: 'Fotos {0} de {1}',
        videoPagination: 'Vídeo {0} de {1}',
        papers: 'Documentos',
        registrationDocument: 'Certificado de registro',
        cocDocument: 'C.O.C.',
        maintenanceCard: 'Historial de mantenimiento',
        detailsTitle: 'Datos del vehículo',
    },
    asset: {
        type: {
            Bus: 'Autobús',
        },
        class: {
            base: 'Clase',
            ClassOne: 'Urbano',
            ClassTwo: 'Interurbano',
            ClassThree: 'Turístico',
            ClassA: 'Clase A',
            ClassB: 'Clase B',
            SchoolBus: 'Autobús escolar',
        },
        kilometers: 'Kilometraje',
        firstRegistration: 'Matrícula',
        antiPollution: 'Normativa anticontaminación',
        euroNorm: {
            Euro0: 'Euro 0',
            Euro1: 'Euro 1',
            Euro2: 'Euro 2',
            Euro3: 'Euro 3',
            Euro4: 'Euro 4',
            Euro5: 'Euro 5',
            Euro5Eev: 'Euro 5 EEV',
            Euro6: 'Euro 6',
            Euro6a: 'Euro 6a',
            Euro6c: 'Euro 6c',
            Euro6dTemp: 'Euro 6d-TEMP',
            Euro6d: 'Euro 6d',
        },
        toBeScraped: 'A desguazar',
        expiredInspection: 'Revisión caducada',
        additionalNotes: 'Especificación del vehículo',
        description: 'Descripción',
        version: 'Versión',
        geographicalLocation: 'Ubicación',
        vehicleCondition: {
            base: 'Estado del vehículo',
            Blocked: 'No funciona',
            Rollable: 'Remolcable',
            Running: 'Marchando',
        },
        length: 'Longitud',
        height: 'Altura',
        width: 'Anchura',
        vin: 'Marco',
        converter: 'Equipamiento',
        visitDate: {
            base: 'Visible',
            fromTo: 'el {date} del {fromTime} al {toTime}',
        },
        inspectionExpiryDate: 'Fecha límite de revisión',
        doors: {
            base: 'Puertas',
            Double: '2',
            DoubleDouble: '2+2',
            DoubleDoubleDouble: '2+2+2',
            DoubleDoubleSingle: '2+2+1',
            DoubleSingleSingle: '2+1+1',
            Single: '1',
            SingleDouble: '1+2',
            SingleSingle: '1+1',
            SingleDoubleSingle: '1+2+1',
            SingleSingleSingle: '1+1+1',
        },
        seatUpholstery: {
            base: 'Cubierta del asiento',
            Fabric: 'Tejido',
            Leather: 'Cuero',
            Plastic: 'Plástico',
            FabricLeather: 'Cuero/tejido',
        },
        totalCapacity: 'Número total de asientos',
        seats: 'Asientos',
        seatsFromS1: 'Asientos incl. libro de conducción (S1)',
        standingPlaces: 'Plazas de pie',
        standingPlacesFromS2: 'Plazas de pie según el permiso de conducción (S2)',
        wheelchairPlaces: 'Plazas en silla de ruedas',
        companionSeats: 'Plazas para acompañantes',
        wheelchairRamp: {
            base: 'Tipo de rampa para discapacitados',
            Absent: 'Ausente',
            Electric: 'Eléctrica',
            Manual: 'Manual',
            Electropneumatic: 'Electroneumático',
        },
        luggageCapacity: 'Capacidad del maletero',
        passThroughLuggageCompartment: 'Maletero pasante',
        hatBox: 'Caja para sombreros',
        transmission: 'Caja de cambios',
        gearboxType: {
            base: 'Tipo de caja de cambios',
            Auto: 'Automático',
            Manual: 'Manual',
            SemiAuto: 'Semiautomático',
        },
        gearboxBrand: 'Marca de la caja de cambios',
        gearboxModel: 'Modelo de caja de cambios',
        glasses: {
            base: 'Gafas',
            Single: 'Individuos',
            Double: 'Doble',
        },
        openableWindows: 'Ventanas de apertura',
        engine: 'Motor',
        engineBrand: 'Marca del motor',
        engineModel: 'Modelo de motor',
        hp: 'Potencia',
        kw: 'Potencia',
        displacement: 'Cilindrada',
        cylinders: 'Número de cilindros',
        fuel: {
            base: 'Alimentación',
            CNG: 'GNC',
            Electric: 'Electricidad',
            GPL: 'GLP',
            Diesel: 'Gasóleo',
            Gasoline: 'Gasolina',
            Hybrid: 'Híbrido',
            Hydrogen: 'Hidrógeno',
        },
        fuelTankCapacity: 'Capacidad del depósito',
        gasTanksCapacity: 'Depósitos de gas',
        gasTanksMaterial: {
            base: 'Material del depósito',
            Aluminum: 'Aluminio',
            Composite: 'Compuesto',
            Steel: 'Acero',
        },
        batteryPower: 'Capacidad de la batería',
        chargingType: 'Tipo de carga',
        axlesTires: 'Ejes y neumáticos',
        wheelSize: 'Tamaño de los neumáticos',
        wheels: 'Número de neumáticos (dobles o simples)',
        axles: 'Ejes',
        wheelsSizeAxle1: 'Tamaño de la rueda eje 1',
        wheelsSizeAxle2: 'Tamaño de la rueda Eje 2',
        wheelsSizeAxle3: 'Tamaño rueda eje 3',
        alloyWheels: 'Llantas de aleación',
        spareWheel: 'Rueda de repuesto',
        firePreventionSystem: 'Sistema contra incendios',
        firePreventionSystemBrand: 'Marca del sistema contra incendios',
        abs: 'ABS',
        asr: 'ASR',
        retarder: 'Retardador',
        breathalyzer: 'Respirador',
        tachograph: 'Tacógrafo',
        indicatorSigns: 'Paneles indicadores',
        indicatorSignsBrand: 'Fabricante del panel indicador',
        accessories: 'Accesorios',
        cruiseControl: {
            base: 'Control de crucero',
            No: 'No',
            Adaptive: 'Adaptativo',
            Standard: 'Estándar',
        },
        deckType: {
            base: 'Flatbed',
            LowEntry: 'Entrada baja',
            LowFloor: 'Piso Bajo',
            NormalFloor: 'Piso Normal',
        },
        acDriver: 'Conductor de aire acondicionado',
        driverBerth: 'Cama del conductor',
        wifi: 'Wi-Fi',
        cctv: 'Videovigilancia (CCTV)',
        internalCameras: 'Cámaras internas',
        externalCameras: 'Cámaras externas',
        retrocamera: 'Cámara de visión trasera',
        microphone: 'Micrófono',
        cdPlayer: 'Reproductor de CD',
        dvdPlayer: 'Reproductor de DVD',
        usbPlayer: 'Reproductor de radio USB',
        heater: 'Calefacción',
        preHeater: 'Precalentador',
        kitchen: 'Cocina',
        coffeeMachine: 'Cafetera',
        refrigerator: 'Nevera',
        toilet: 'Aseo',
        safetyBelts: 'Cinturones de seguridad',
        acPassengers: 'Aire acondicionado para pasajeros',
        curtains: 'Parasoles',
        seatPocket: 'Red de estiba',
        footRest: 'Reposapiés',
        handRest: 'Reposabrazos',
        seatsMonitor: 'Monitores de asiento',
        seatsRadio: 'Radio del asiento',
        seatsUsbPorts: 'USB a asientos',
        socket220: 'Toma de corriente conductor 220V',
        seatsSocket220: 'Tomas de corriente 220V asientos',
        extendableSeats: 'Asientos extensibles',
        recliningSeats: 'Asientos reclinables',
        coffeeTables: 'Mesas de asiento',
    },
    auction: {
        countdown: {
            startsIn: 'Comienzos entre',
            endsIn: 'Termina entre',
            days: 'g',
            hours: 'h',
            minutes: 'm',
            seconds: 's',
        },
        bail: {
            title: 'Vincula la fianza a participar en la subasta',
            description: 'Para participar en esta subasta, debe vincular su depósito de garantía a través de un método de pago válido.',
            amount: 'DEPÓSITO DE GARANTÍA',
            note: 'El depósito de puja será liberado al final de la subasta si usted no se encuentra entre los mejores postores.',
            subnote: 'Los depósitos de garantía no son iguales para todos los bienes y pueden variar en función de la base de la subasta',
            error: 'Se ha producido un error al procesar su transacción, por favor inténtelo de nuevo más tarde o póngase en contacto con soporte',
            submit: 'Vincular depósito de garantía',
        },
        bid: {
            title: 'Conviértete en el mejor postor ahora',
            description: 'Recuerde que su puja es vinculante y que, en caso de no ser superada, se compromete a pagarla.',
            yourNewOffer: 'Tu nueva oferta',
            confirm: 'Confirmar oferta ahora',
            cancel: 'Cancelar',
            success: 'Oferta aceptada',
            total: 'Total',
            outbidError: {
                title: 'Otro usuario ha superado tu oferta',
                description: 'La oferta que has seleccionado ya ha sido superada por otro usuario, por favor cierra y actualiza tu oferta.',
                close: 'Cerrar',
            },
            expiredError: {
                title: 'La subasta ha finalizado',
                description: 'No se pueden realizar más pujas.',
            },
        },
        bidButton: 'Haga su puja',
        auctionPanel: {
            reservePrice: 'Precio de reserva',
            startingPrice: 'Puja inicial',
            current: 'Actual',
            wonAuction: '¡Subasta adjudicada!',
            state: {
                Closed: 'Subasta finalizada',
                Canceled: 'Subasta cancelada',
                Draft: 'Proyecto de subasta',
            },
            maxBidder: '¡Tu puja es la mejor!',
            outBid: '¡Oferta superada!',
            negotiationInProgress: 'Negociación en curso',
            feeNotice: '+ comisión ({0})',
            feeNoticeLink: '¿qué son?',
            priceNotAvailable: 'N/A',
            bidNotice: {
                reservePriceNotReached: {
                    base: 'Precio de reserva {0}',
                    notReached: 'aún no alcanzado',
                },
                notScrapyard:
                    'Subasta reservada a demoledores inscritos en el registro de gestión de residuos en cat. 5 y operadores de C.E.R. código 16.01.04',
                sameCompany: {
                    Running: 'El vehículo pertenece a su empresa',
                    base: 'El vehículo pertenece a su empresa',
                },
                isAdmin: 'No puede participar porque su usuario tiene rol de Admin',
            },
        },
        needSupport: '¿Necesita ayuda?',
        contactUs: 'Contacta con el servicio de atención al cliente de Fleequid',
    },
    auctions: {
        page: {
            asset: {
                Bus: 'autobuses',
            },
            title: 'Descubre todos los {title} en Fleequid',
            results: '{total} subastas visibles',
            emptyStateTitle: 'No hay resultados',
            emptyStateText: 'Los filtros aplicados no produjeron resultados.',
            resetFilters: 'Eliminar filtros',
        },
        filters: {
            applied: 'Filtros aplicados',
            button: {
                label: 'Filtrar barras',
            },
            modal: {
                title: 'Filtrar subastas',
                back: 'Volver a resultados',
            },
            label: 'Filtros',
            class: 'Clase',
            brand: 'Marca, modelo, versión',
            brandFilter: 'Marca',
            brandFilterAnyOption: 'Todas las marcas',
            modelFilter: 'Modelo',
            modelFilterAnyOption: 'Todos los modelos',
            versionFilter: 'Versión',
            versionFilterAnyOption: 'Todas las versiones',
            antiPollution: 'Normativa anticontaminación',
            registration: 'Registro',
            firstRegistrationYearFrom: 'A partir de (año)',
            firstRegistrationYearTo: 'Hasta (año)',
            firstRegistrationYearAnyOption: 'Cualquier año',
            kilometers: 'Kilometraje',
            kmFrom: 'A partir de',
            kmTo: 'Hasta',
            kmAnyOption: 'Cualquier kilometraje',
            scrapping: {
                title: 'Restricciones de demolición',
                help: '¿Cuáles son las restricciones de demolición?',
                all: 'Todos los vehículos',
                requireScrapping: 'Con desguace obligatorio',
                doNotRequireScrapping: 'Sin desguace obligatorio',
            },
            fuel: 'Alimentación',
            empty: 'Sin filtro aplicado',
        },
        appliedFilters: {
            firstRegistrationYearFrom: 'de: {year}',
            firstRegistrationYearTo: 'a: {year}',
            kmFrom: 'de: {km}',
            kmTo: 'a: {km}',
            reset: 'Eliminar filtros',
            appliedFiltersCounter: '{count} filtro activo | {count} filtros activos',
        },
    },
    homepage: {
        link: 'Home',
        hero: {
            title: 'There are no heavy vehicles',
            subtitle: 'Join our online auctions and buy or sell your bus in a simple, fast and transparent way',
            buttonLabel: 'Participate in auctions',
        },
        whatIsFleequid: {
            title: 'What is Fleequid?',
            description: 'Fleequid is the new way to sell and buy vehicles at auction for your business',
            section: {
                simplicity: {
                    title: 'Simplicity',
                    description: 'A linear and intuitive process, which eliminates endless negotiations',
                },
                speed: {
                    title: 'Speed',
                    description: 'You can buy your new bus in 7 days',
                },
                transparency: {
                    title: 'Transparency',
                    description: 'Clear prices, detailed images, dedicated support',
                },
            },
        },
        vision: {
            caption: 'Our vision',
            title: 'We believe in the benefits of used vehicles. We want to help companies manage the buying and selling of company vehicles, saving time and resources, turning a problem into an opportunity.',
            highlight: 'Forget risks and uncertainties: with us, giving value to your assets becomes simple.',
        },
        howItWorks: {
            title: 'How it works',
            description: 'We strive to keep our platform simple and intuitive so you can easily manage your bus fleet',
            signUp: {
                title: 'Sign up for Fleequid',
                create: 'Create a {account} using your company details',
                account: 'new account',
                verify: 'Verify your details and {browse} the site',
                browse: 'start browsing',
            },
            join: {
                title: 'Participate in auctions',
                choose: 'Choose a {bus} from the list',
                bus: 'bus',
                add: 'Add a valid {payment} to start bidding',
                payment: 'payment method',
                follow: 'Follow the auction and {win}',
                win: 'win your favorite buses',
            },
            sell: {
                title: 'Sell your company buses',
                letUsKnow: 'Let us know all the details of the {bus}',
                bus: 'bus for sale',
                choose: 'Choose a {price} for your bus',
                price: 'minimum price', // TODO
                wait: 'Wait for the auction to end to {confirm}',
                confirm: 'confirm the sale',
            },
        },
        whyFleequid: {
            title: 'Why choose Fleequid?',
            description: 'We want to become your trusted partner in managing your fleet',
            section: {
                euReach: {
                    title: 'European reach',
                    description: 'Save time by avoiding undecided people and simplifying administrative and documentary procedures.',
                },
                unifiedBilling: {
                    title: 'Unified billing',
                    description: 'Enjoy the convenience of having an efficient platform and a single company name to invoice.',
                },
                easyAuctions: {
                    title: 'Easy and convenient auctions',
                    description: "Thanks to our pre- and post-purchase support service, participating in auctions will be child's play.",
                },
                operationalEfficiency: {
                    title: 'Operational efficiency',
                    description: 'Save time by avoiding the indecisive and simplifying paperwork.',
                },
                sell: {
                    title: 'Sell your vehicles',
                    description: 'Fleequid supports you in setting the sales price and creating the photo dossier.',
                    cta: 'Sell on Fleequid',
                },
            },
        },
        startToday: {
            title: 'Start today on Fleequid',
            description: 'View all available auctions and get ready for the best products',
            cta: 'Participate in auctions',
        },
    },
    faq: {
        title: 'Frequently asked questions',
        start: {
            base: 'How to get started',
            participate: {
                q: 'How can I participate in an online auction?',
                a: 'To join online auctions on Fleequid you need to sign up at {0} providing your business information and sending the required documents. Once you’ve done that, you can check out the pages with the ongoing auctions, and place one or more bids to compete for the purchase of a vehicle. Auctions are held electronically, and you can place bids until the auction closes. The auction ends when the user who submitted the highest bid awards the vehicle, as long as the bid is at least the reserve price, or if the seller accepts a bid that is lower than the reserve price. ',
                link: 'https://fleequid.com/en/signup',
            },
            reservePrice: {
                q: 'What is the reserve price?',
                a: 'The reserve price is the minimum amount that must be exceeded for the auction to be successful. If this amount is not reached during the auction, it’s up to the seller to decide whether they are willing to accept the best offer they received anyway. The reserve price is a higher amount than the starting bid: it is set by the seller before the start of the auction and will not be visible to the public, who only knows the highest bid made up to that moment.',
            },
            countries: {
                q: 'In which countries is it possible to buy and sell buses through Fleequid?',
                a: 'Fleequid operates in Italy, in the European Union and in several non-EU countries, complying with the regulations of each nation. If you have any doubts about the possibility of using Fleequid from your country, please contact our customer service at {0}.',
            },
            bureaucracy: {
                q: 'Who is responsible for property transfers, vehicle registration and every documentation aspect?',
                a: "Fleequid will communicate to seller and buyer how to handle documentation aspects and payments related to the property transfer, depending on the specifics of the deal (between Italian companies, EU, and non-EU) and on the platform's role in the sales process. However, registration of the vehicle in the country of destination is the responsibility of the buyer.",
            },
            toBeScraped: {
                q: 'What are vehicles intended for scrapping?',
                a: 'Vehicles intended for scrapping are those which, being no longer suitable for circulation and the transportation of people, must necessarily, according to current laws or regulation rules, undergo a recovery and partial reuse process of their components as spare parts. Participation in auctions for these vehicles is only permitted for dismantlers listed in the waste management register in category 5 and managers of the C.E.R. code 16.01.04. At the moment, the auctions for vehicles intended for scrapping are only available in Italy.',
            },
            vies: {
                q: 'What is Vies? Why is it mandatory to be included?',
                a: 'It’s a search engine (not a database) created by the European Commission to facilitate sales between companies in UE. It’s an electronic mean of validating VAT-identification numbers of economic operators registered in the European Union for cross border transactions on goods or services. For this reason, European companies that want to be on Fleequid must necessarily be present there.',
            },
        },
        sell: {
            base: 'How to sell',
            selling: {
                q: 'How can I start selling on Fleequid?',
                a: 'If you have a bus to sell, please contact our customer service by writing to {0} or fill out the form found at {1}, providing information about the vehicle you want to sell.  You also need to register your company as a seller on the platform.',
            },

            startingPrice: {
                q: 'How is the starting price defined?',
                a: 'The starting price is the minimum price for the vehicle above which potential buyers can place their bids or make counteroffers on the Marketplace. The starting bid is calculated by Fleequid based on its technical assessment of the vehicle and must be lower than the reserve price, which is instead set by the seller.',
            },
            delivery: {
                q: 'How is the vehicle delivered?',
                a: 'After receiving payment from the buyer, the seller must enable the buyer to collect the vehicle; the buyer must promptly proceed with the pickup, which must take place within ten days from the date Fleequid informs the seller of the completion of the de-registration procedures in the country of origin of the bus. For each day of delay beyond the specified timeline, Fleequid will impose a penalty.',
            },
            howLong: {
                q: 'How long does it take for the bus to be sold?',
                a: 'When a vehicle is uploaded to the platform, Fleequid shares with the seller the start and end dates of the auction and the information about pre-auction visibility; these dates are established by Fleequid based on the specifics of the vehicle to be sold. If the sale did not conclude successfully, Fleequid would still have the right to hold up to 3 consecutive auctions within a total of 30 days.',
            },
        },
        buy: {
            base: 'How to buy',
            inspection: {
                q: 'Is it possible to inspect vehicles before bidding?',
                a: "The seller can decide whether to offer the option of live inspection of the vehicle. If allowed, the seller will define a specific day for live visits and inspections of the vehicle, directly at the seller's location.",
            },
            claims: {
                q: 'How do you handle hidden defects and complaints?',
                a: 'Fleequid acts as an intermediary between seller and buyer and, as such, is not responsible for any faults, whether hidden or visible, defects, wear and tear, irregularities, unsuitability for use, as well as errors or inaccuracies in the declarations made by the seller. By posting their auction on Fleequid, the seller commits to transparency and to providing accurate information about the condition of the vehicle; at the same time, the buyer must commit to reporting any discrepancies in a timely manner (3 days for apparent defects) within one month from the pick-up for hidden defects of the vehicle. Fleequid is available to help buyers manage complaints and provides s-pecific conditions for their handling.',
            },
            paymentMethods: {
                q: 'What payment methods are accepted?',
                a: 'Fleequid accepts bank transfers and credit card payments. More specifically, the security deposit must be paid only by credit card; the purchase of the vehicle, the payment of commissions and any additional costs must be done via bank transfer. ',
            },
            howPaymentWorks: {
                q: 'How does payment work?',
                a: 'The user who wins the auction is required to pay an amount that includes the winning price, the selling fee for Fleequid and any other agreed-upon fees (see General Conditions for more details). The buyer must pay the amount due by bank transfer no later than three days after receiving the corresponding invoice. Partial payments are not allowed.',
            },
            deposit: {
                q: 'How does the security deposit work?',
                a: 'The security deposit is the sum of money that the user gives to Fleequid when placing a bid in the auction, as a guarantee of the seriousness of the offer itself. When the auction ends all deposits are released, except those of the first and second place winners. If the winner finalizes the purchase of the vehicle, by making the bank transfer for the agreed amount (no later than 3 days after receiving the corresponding invoice), both their deposit and that of the second bidder will be released. If the winner fails to make payment within the agreed time, they will lose both the bus and the security deposit. In this case, the second-place winner will automatically become the new winner of the auction and, by purchasing the vehicle by bank transfer, the security deposit will be returned.',
            },
        },
        contactUs:
            'We hope you have found all the answers to your questions on this page, but if not, we are always happy to help. Call us on {0}, or contact us by writing to {1}.',
    },
    footer: {
        cookiePolicy: {
            base: 'Política de cookies',
            changeConsent: 'Cambiar su consentimiento',
            revokeConsent: 'Revocar su consentimiento',
        },
        explore: 'Navegar por',
        legal: 'Información legal',
        support: '¿Necesita apoyo?',
        vat: 'NÚMERO DE IVA',
        backTop: 'Volver arriba',
    },
    menu: {
        buy: 'Comprar',
        sell: 'Vender',
        faq: 'F.A.Q.',
    },
    mktg: {
        whyFleequid: {
            title: '¿Por qué Fleequid?',
            deal: {
                title: 'Compra y venta simplificadas',
                description: 'Una plataforma tecnológica en lugar de negociaciones con partes indecisas',
            },
            processes: {
                title: 'Procesos rápidos',
                description: 'Fleequid reduce el tiempo y ahorra energía a su personal',
            },
            support: {
                title: 'Soporte transparente',
                description: 'Todo está claro, desde el registro hasta la entrega',
            },
        },
    },
    notifications: {
        success: 'Éxito',
        error: 'Error',
        genericError: 'Se ha producido un error. Por favor, inténtelo de nuevo más tarde.',
        tryAgain: 'Por favor, inténtelo de nuevo',
        userOutBid: {
            base: 'Tu puja de {prevBidAmount} por {subject} ha sido superada, el nuevo precio actual es {currentBidAmount}',
            inAuction: 'esta subasta',
            linkedAuction: '{brand} {model} {version}',
        },
        userBailAuthorized: {
            inAuction: 'Ha sido autorizado a participar en esta subasta',
            linkedAuction: 'Está autorizado a participar en esta subasta {brand} {model}',
        },
        userMaxBidderRestored: {
            base: 'Tu puja de {prevBidAmount} por {subject} ¡es la mejor!',
            inAuction: 'esta subasta',
            linkedAuction: '{brand} {model} {version}',
        },
    },
    privacy: {
        link: 'Política de privacidad',
    },
    seo: {
        auction: {
            title: '{asset} {brand} {model} {version} {vin}',
            description:
                'Comprar {brand} {model} {version}, {asset} de segunda mano en Fleequid.com: participa en la subasta B2B de y puja por {asset} {vin} marca {brand}, modelo {model} y versión {version}.',
        },
        auctions: {
            title: 'venta de {0} de segunda mano',
            description:
                'Comprar {0} de segunda mano en línea en Fleequid.com : participe fácilmente en las subastas B2B de los mejores {1} de ocasión en venta.',
        },
        cookiePolicy: {
            title: 'Política de Cookies',
            description: 'Política de cookies del portal Fleequid: lee cómo gestionamos las cookies que utilizamos para hacer cómoda la navegación.',
        },
        homepage: {
            title: 'Venta de Autobuses de Ocasión',
            description:
                'Compra o vende autobuses y vehículos industriales en subasta: Únete a Fleequid y participa fácilmente en subastas B2B de los mejores vehículos industriales.',
        },
        privacyPolicy: {
            title: 'Política de privacidad',
            description: 'Política de privacidad del portal Fleequid: explicamos claramente cómo tratamos los datos de navegación y todos tus datos.',
        },
        signup: {
            title: 'Subastas de vehículos: registro',
            description:
                'Regístrese ahora en Fleequid para participar de la forma más fácil y cómoda en subastas de vehículos industriales, autobuses y autocares nuevos y usados.',
            confirm: {
                title: 'Verificación de correo electrónico',
            },
        },
        termsAndConditions: {
            title: 'Términos y condiciones',
            description:
                'Términos y condiciones de compraventa en Fleequid, el portal de subastas y compraventa online de vehículos industriales y autobuses.',
        },
        pricingSeller: {
            title: 'Comisiones del vendedor',
            description:
                'Comisiones de subasta cobradas en Fleequid.com: detalles de precios y especificaciones sobre el método de cobro de comisiones en las subastas de venta.',
        },
        pricingBuyer: {
            title: 'Comisiones del comprador',
            description:
                'Comisiones de subasta cobradas en Fleequid.com: detalles de precios y especificaciones sobre el método de cobro de comisiones relacionadas con las subastas de venta.',
        },
    },
    signup: {
        link: 'Registrarse',
        companyRegistration: 'Registre su empresa',
        subtitle: 'Introduce los datos de tu empresa y entra en un nuevo mundo de ofertas.',
        companyInfo: 'Datos de la empresa',
        companyDocuments: 'Documentos de la empresa',
        contactPerson: 'Datos de contacto de la empresa',
        accessCredentials: 'Credenciales de acceso',
        fleetHandling: 'Movimiento de la flota',
        fields: {
            address: 'Dirección',
            postCode: 'CÓDIGO POSTAL',
            city: 'Ciudad',
            province: 'Provincia',
            businessName: 'Nombre de la empresa',
            country: 'País',
            pec: 'PEC',
            sdi: 'Código IDE',
            vatId: 'CIF/IVA',
            companyRegistrationReportFile: 'Cámara de Comercio Visura',
            firstname: 'Nombre',
            lastname: 'Apellidos',
            phone: 'Número de teléfono',
            type: {
                label: 'Tipo de operador',
                Dealer: 'Operador',
                PeopleTransportOperator: 'Operador de transporte de pasajeros',
                Scrapyard: 'Demoledor',
                Other: 'Otros',
            },
            email: 'Correo electrónico',
            password: 'Contraseña',
            confirmPassword: 'Confirmación de contraseña',
            stats: {
                sales: '¿Cuántos autobuses tiene previsto vender en {year}?',
                purchase: '¿Cuántos autobuses tiene previsto comprar en {year}?',
                unknown: 'No lo sé',
            },
            consent: 'Consenso',
            termsConditions: 'He leído y acepto las {0} del servicio.',
            vexatiousClauses: 'He leído y acepto las siguientes cláusulas:',
            privacy: 'He leído y acepto las {0}.',
        },
        hint: {
            phone: {
                text: 'Formato del número',
                rule: 'Por favor, introduzca el número de teléfono en el formato +39 1234567890',
            },
        },
        validations: {
            requiredSDIOrPec: 'Introduzca al menos un código IDE o una dirección PEC',
            companyRegistration: 'El archivo seleccionado es demasiado grande. El tamaño máximo permitido es {size} MB',
        },
        success: {
            title: 'Muchas gracias por completar el registro',
            p1: 'Sus datos se han recibido correctamente.',
            p2: 'En breve recibirá un correo electrónico de confirmación con un enlace en el que deberá hacer clic para verificar su dirección de correo electrónico.',
            p3: 'Por favor, compruebe también la carpeta de correo no deseado de su buzón ya que, debido a sus preferencias, el correo electrónico puede haber sido marcado como correo no deseado.',
            p4: 'Le invitamos a ver las subastas en línea y averiguar si hay algún vehículo de su interés. ¿Quiere vender sus autobuses en la próxima subasta? Póngase en contacto con nosotros en',
            p5: '¡Gracias por elegirnos!',
        },
        confirm: {
            success: {
                title: '¡Tu email ha sido verificado!',
                p1: 'Gracias por confirmar su {0} correo electrónico.',
                p2: 'Su cuenta ya está activa y puede acceder a todas las funciones de nuestro servicio.',
                p3: 'Si tienes alguna pregunta o necesitas ayuda, ponte en contacto con nuestro equipo de soporte en {email}. ¡Bienvenido a bordo!',
            },
            error: {
                title: 'Error en la verificación del correo electrónico',
                p1: 'Lo sentimos, pero la verificación de su correo electrónico no ha tenido éxito.',
                p2: 'Puede que haya habido un problema con el enlace de confirmación.',
                p3: 'Inténtalo de nuevo haciendo clic en el enlace de verificación que te hemos enviado. Si el problema persiste, ponte en contacto con nuestro equipo de asistencia para obtener ayuda.',
            },
            buyCta: 'Participar en subastas',
            hpCta: 'Volver a la página de inicio',
        },
    },
    termsConditions: {
        link: 'Términos y condiciones',
    },
    login: {
        email: 'Correo electrónico',
        password: 'Contraseña',
        formSubtitle: 'Introduce tus credenciales de Fleequid para participar en las subastas',
        forgotPassword: '¿Has olvidado tu contraseña?',
        or: 'O',
        unregistered: '¿No tienes una cuenta Fleequid?',
        signupNow: 'Regístrate ahora',
    },
    passwordReset: {
        request: {
            title: 'Restablecer contraseña',
            subTitle: 'Introduzca su correo electrónico y le enviaremos un mensaje para restablecer su contraseña.',
            submitButton: 'Enviar correo electrónico',
            onSuccessTitle: 'Solicitud aceptada',
            onSuccessDescription: 'Recibirá un correo electrónico con el procedimiento para completar el restablecimiento de la contraseña.',
        },
        reset: {
            title: 'Restablecer contraseña',
            description: 'Por favor, introduzca una nueva contraseña. Una vez confirmado el cambio se invalidarán todas las sesiones activas.',
            newPassword: 'Nueva contraseña',
            confirmNewPassword: 'Confirmar nueva contraseña',
            submitButton: 'Cambiar contraseña',
            updateSuccess: 'Contraseña cambiada con éxito',
            tokenExpired: 'El token de restablecimiento de contraseña ha caducado.',
            tokenInvalid: 'La contraseña no es válida.',
            startOverDescription:
                'Vuelva a realizar el procedimiento de solicitud de restablecimiento de contraseña, se generará un nuevo testigo al mismo tiempo.',
            startOverButton: 'Inténtelo de nuevo',
        },
    },
    validation: {
        msg: {
            characters: 'Uno o más caracteres inválidos',
            email: 'El correo electrónico debe tener el formato usuario{0}dominio.ext',
            incomplete: '{0} incompleto',
            invalid: "{'@'} inválido",
            invalidBid: 'El importe introducido no es válido',
            missing: '{field} Falta',
            max: 'Longitud máxima {field} carácter | Longitud máxima {field} carácter',
            min: 'Longitud mínima {count} carácter | Longitud mínima {count} carácter',
            length: 'Longitud debe ser exactamente {count} carácter | Longitud debe ser exactamente {count} carácter',
            passwordWeak: 'Contraseña demasiado débil',
            passwordMatch: 'Las contraseñas no coinciden',
            phone: 'Introduzca sólo números, espacios y el carácter +',
            recaptcha: 'Error de validación reCAPTCHA',
            required: '{count} obligatorio',
        },
        hint: {
            password: {
                text: 'Reglas para una contraseña eficaz',
                rules: {
                    length: 'Al menos 8 caracteres',
                    uppercase: '1 carácter en mayúscula',
                    lowercase: '1 carácter en minúscula',
                    symbol: '1 carácter especial',
                    number: '1 número',
                },
            },
        },
    },
    profile: {
        title: 'Tu perfil',
        companyData: {
            readOnlyDisclaimer: 'Los datos de la empresa son de sólo lectura, para solicitar un cambio póngase en contacto con nosotros en {count}.',
        },
        userData: {
            saveChanges: 'Confirmar nuevos datos',
            cancelChanges: 'Cancelar cambios',
            updateSuccess: 'Datos de contacto de la empresa guardados correctamente',
        },
        changePassword: {
            title: 'Cambiar contraseña',
            description: 'El cambio de contraseña invalidará todas las demás sesiones activas excepto la actual.',
            currentPassword: 'Contraseña actual',
            newPassword: 'Nueva contraseña',
            confirmNewPassword: 'Confirmar nueva contraseña',
            submitButton: 'Confirmar nueva contraseña',
            updateSuccess: 'Contraseña modificada correctamente',
        },
        changeEmail: {
            title: 'Cambiar email',
            description:
                'Recibirás un correo electrónico con un enlace para confirmar el nuevo correo electrónico. El cambio de correo electrónico invalidará todas las sesiones activas.',
            password: 'Contraseña actual',
            email: 'Correo electrónico',
            submitButton: 'Confirmar nuevo email',
            updateSuccess: 'Email cambiado con éxito',
        },
    },
    sessionExpired: {
        title: 'Su sesión ha expirado',
        description:
            'Su sesión ha sido invalidada porque sus credenciales de acceso han sido cambiadas. Por favor, inicie sesión con las nuevas credenciales.',
        close: 'Continuar',
    },
};

export default es;
