/*
    N.B. Keep this comment to preserve line number alignment among locale files:
    Same line = same translation entry
 */

const en = {
    currency: {
        style: 'currency',
        currency: 'EUR',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
    },
    integer: {
        style: 'decimal',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
    },
    decimal: {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    },
    optionalDecimal: {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    },
};

export default en;
