import en from './en';

type i18nKeys = keyof typeof en;
type i18nValues = (typeof en)[i18nKeys];

const pl: Record<i18nKeys, i18nValues> = {
    account: {
        bids: {
            title: 'Twoje oferty',
            emptyState: {
                base: 'Nie złożyłeś jeszcze oferty.',
                cta: 'Weź udział w aukcjach',
            },
            filters: {
                bidPosition: {
                    base: 'Orzekanie',
                    anyOption: 'Wszystkie oferty',
                    maxBidder: 'Twoja oferta jest najlepsza',
                    outbid: 'Przebity',
                },
                state: {
                    base: 'Status aukcji',
                    anyOption: 'Wszystkie aukcje',
                    Closed: 'Zakończone aukcje',
                    Running: 'Aukcje w toku',
                },
            },
        },
        sales: {
            title: 'Twoja sprzedaż',
            emptyStateText: 'Nie ma aukcji ze sprzedawcą powiązanym z tym kontem.',
        },
    },
    common: {
        logout: 'Wyjście',
        login: 'Zaloguj się',
        loginSignup: 'Zaloguj/Zarejestruj się',
        loginTitle: 'Zaloguj się do Fleequid',
        profile: 'Twój profil',
        searchPlaceholder: 'Szukaj...',
        searchNoResults: 'Nie znaleziono żadnych wyników dla"{query}"',
        email: 'Email',
        yourAccount: 'Twoje konto',
    },
    cookie: {
        name: 'Imię',
        provider: 'Dostawca',
        purpose: 'Cel',
        expiry: 'Data ważności',
        type: 'Typ',
    },
    error: {
        '404': {
            title: '404',
            subtitle: 'Ups, nie znaleziono strony',
            description: 'Przepraszamy, żądana strona nie istnieje. Na Fleequid znajdziesz jednak o wiele więcej do odkrycia.',
            button: 'Powrót do strony głównej',
        },
        '500': {
            title: '500',
            subtitle: 'Ups, wystąpił błąd',
            description: 'Przepraszamy za niedogodności. Pracujemy już nad rozwiązaniem problemu.',
            help: {
                base: 'W międzyczasie spróbuj ponownie później lub {contactUs}',
                contactUs: 'skontaktuj się z nami pod numerem {email} lub {phone} w nagłych przypadkach',
            },
            button: 'Powrót do strony głównej',
        },
        backend: {
            InvalidEnumValue: 'Nieprawidłowy typ danych',
            InvalidDate: 'Nieprawidłowy format daty',
            InvalidLength: 'Nieprawidłowa długość. Upewnij się, że wprowadziłeś tylko niezbędne znaki',
            NotAString: 'Nieprawidłowy typ; wprowadź tekst',
            EmptyString: 'Brakujące dane. Uzupełnij wszystkie wymagane informacje',
            PrismaError: 'Nie można zapisać danych; wypróbuj inną wartość',
            AuthAuthenticationVersionMismatch: 'Twoja sesja wygasła. Zaloguj się ponownie, aby kontynuować',
            AuthWrongCredentials: 'Nieprawidłowe poświadczenia',
            AuthNoUserByEmail: 'Nie ma konta powiązanego z wprowadzonym adresem e-mail.',
            AuthWrongPassword: 'Wprowadzone hasło jest nieprawidłowe.',
            UserIsBanned: 'Twoje konto zostało zawieszone. Prosimy o kontakt w celu uzyskania dalszych informacji.',
            AuthEmailNotVerified: 'Wprowadzony adres e-mail nie został jeszcze zweryfikowany. Wysłaliśmy nowy link do weryfikacji adresu e-mail.',
            SignupUserAlreadyExists: 'Adres e-mail jest już zarejestrowany, jeśli zapomniałeś hasła, spróbuj je odzyskać.',
            UploadFileError: 'Błąd podczas ładowania pliku',
            IsNotTrue: 'Brakujący wybór. Zaznacz opcję obowiązkową',
            EntityNotFound: 'Nie znaleziono wyników',
            PasswordResetEmailNotExists: 'Nie ma konta powiązanego z tym adresem e-mail',
            EmailChangeWrongPassword: 'Nie można zmienić adresu e-mail, ponieważ hasło jest nieprawidłowe',
            CantBidUserCompanyEqSellerCompany: 'Ten zasób jest własnością Twojej firmy, nie możesz go kupić.',
            authentication: 'Błąd uwierzytelniania; spróbuj zalogować się ponownie',
        },
        default: {
            title: 'Błąd',
            subtitle: 'Wystąpił nieoczekiwany błąd',
            description: 'Pracujemy już nad rozwiązaniem problemu.',
            button: 'Powrót do strony głównej',
        },
    },
    a11y: {
        auctionDetail: 'Dowiedz się więcej o {name}',
    },
    adp: {
        imageGallery: 'Pełna galeria ({totalImages})',
        videoGallery: 'Oglądaj filmy ({totalVideos})',
        imagePagination: 'Zdjęcia {0} z {1}',
        videoPagination: 'Wideo {0} z {1}',
        papers: 'Dokumenty',
        registrationDocument: 'Świadectwo rejestracji',
        cocDocument: 'C.O.C.',
        maintenanceCard: 'Historia konserwacji',
        detailsTitle: 'Szczegóły pojazdu',
    },
    asset: {
        type: {
            Bus: 'Autobus',
        },
        class: {
            base: 'Klasa',
            ClassOne: 'Miejski',
            ClassTwo: 'Międzymiastowa',
            ClassThree: 'Turystyczny',
            ClassA: 'Klasa A',
            ClassB: 'Klasa B',
            SchoolBus: 'Autobus szkolny',
        },
        kilometers: 'Przebieg',
        firstRegistration: 'Rejestracja',
        antiPollution: 'Przepisy dotyczące zapobiegania zanieczyszczeniom',
        euroNorm: {
            Euro0: 'Euro 0',
            Euro1: 'Euro 1',
            Euro2: 'Euro 2',
            Euro3: 'Euro 3',
            Euro4: 'Euro 4',
            Euro5: 'Euro 5',
            Euro5Eev: 'Euro 5 EEV',
            Euro6: 'Euro 6',
            Euro6a: 'Euro 6a',
            Euro6c: 'Euro 6c',
            Euro6dTemp: 'Euro 6d-TEMP',
            Euro6d: 'Euro 6d',
        },
        toBeScraped: 'Do złomowania',
        expiredInspection: 'Remont wygasł',
        additionalNotes: 'Specyfikacja pojazdu',
        description: 'Opis',
        version: 'Wersja',
        geographicalLocation: 'Lokalizacja',
        vehicleCondition: {
            base: 'Stan pojazdu',
            Blocked: 'Nie działa',
            Rollable: 'Holowalny',
            Running: 'Maszerujący',
        },
        length: 'Długość',
        height: 'Wysokość',
        width: 'Szerokość',
        vin: 'Rama',
        converter: 'Outfitter',
        visitDate: {
            base: 'Widoczny',
            fromTo: 'na {date} od {fromTime} do {toTime}',
        },
        inspectionExpiryDate: 'Termin przeglądu',
        doors: {
            base: 'Drzwi',
            Double: '2',
            DoubleDouble: '2+2',
            DoubleDoubleDouble: '2+2+2',
            DoubleDoubleSingle: '2+2+1',
            DoubleSingleSingle: '2+1+1',
            Single: '1',
            SingleDouble: '1+2',
            SingleSingle: '1+1',
            SingleDoubleSingle: '1+2+1',
            SingleSingleSingle: '1+1+1',
        },
        seatUpholstery: {
            base: 'Pokrycie siedzenia',
            Fabric: 'Tkanina',
            Leather: 'Skóra',
            Plastic: 'Tworzywo sztuczne',
            FabricLeather: 'Skóra/tkanina',
        },
        totalCapacity: 'Całkowita liczba siedzeń',
        seats: 'Siedzenia',
        seatsFromS1: 'Miejsca siedzące wraz z dziennikiem kierowcy (S1)',
        standingPlaces: 'Miejsca stojące',
        standingPlacesFromS2: 'Miejsca stojące zgodnie z prawem jazdy kierowcy (S2)',
        wheelchairPlaces: 'Miejsca na wózki inwalidzkie',
        companionSeats: 'Miejsca dla osób towarzyszących',
        wheelchairRamp: {
            base: 'Podjazd dla niepełnosprawnych',
            Absent: 'Nieobecny',
            Electric: 'Elektryczny',
            Manual: 'Ręczny',
            Electropneumatic: 'Elektropneumatyczny',
        },
        luggageCapacity: 'Pojemność bagażnika',
        passThroughLuggageCompartment: 'Przelotowy przedział bagażowy',
        hatBox: 'Schowek na kapelusze',
        transmission: 'Skrzynia biegów',
        gearboxType: {
            base: 'Typ skrzyni biegów',
            Auto: 'Automatyczna',
            Manual: 'Manualna',
            SemiAuto: 'Półautomatyczny',
        },
        gearboxBrand: 'Przekładnia',
        gearboxModel: 'Model skrzyni biegów',
        glasses: {
            base: 'Okulary',
            Single: 'Osoby',
            Double: 'Podwójne',
        },
        openableWindows: 'Otwieranie okien',
        engine: 'Silnik',
        engineBrand: 'Marka silnika',
        engineModel: 'Model silnika',
        hp: 'Moc',
        kw: 'Moc',
        displacement: 'Pojemność skokowa',
        cylinders: 'Liczba cylindrów',
        fuel: {
            base: 'Zasilanie paliwem',
            CNG: 'CNG',
            Electric: 'Energia elektryczna',
            GPL: 'LPG',
            Diesel: 'Olej napędowy',
            Gasoline: 'Benzyna',
            Hybrid: 'Hybryda',
            Hydrogen: 'Wodór',
        },
        fuelTankCapacity: 'Pojemność zbiornika',
        gasTanksCapacity: 'Zbiorniki gazu',
        gasTanksMaterial: {
            base: 'Materiał zbiornika',
            Aluminum: 'Aluminium',
            Composite: 'Kompozyt',
            Steel: 'Stal',
        },
        batteryPower: 'Pojemność baterii',
        chargingType: 'Typ ładowania',
        axlesTires: 'Osie i opony',
        wheelSize: 'Rozmiar opon',
        wheels: 'Liczba opon (podwójne lub pojedyncze)',
        axles: 'Osie',
        wheelsSizeAxle1: 'Rozmiar koła oś 1',
        wheelsSizeAxle2: 'Rozmiar koła Oś 2',
        wheelsSizeAxle3: 'Rozmiar koła, oś 3',
        alloyWheels: 'Felgi aluminiowe',
        spareWheel: 'Koło zapasowe',
        firePreventionSystem: 'System przeciwpożarowy',
        firePreventionSystemBrand: 'Marka systemu przeciwpożarowego',
        abs: 'ABS',
        asr: 'ASR',
        retarder: 'Retarder',
        breathalyzer: 'Breathalyser',
        tachograph: 'Tachograf',
        indicatorSigns: 'Panele wskaźników',
        indicatorSignsBrand: 'Producent paneli wskaźników',
        accessories: 'Akcesoria',
        cruiseControl: {
            base: 'Tempomat',
            No: 'Nie',
            Adaptive: 'Adaptacyjny',
            Standard: 'Standardowy',
        },
        deckType: {
            base: 'Łóżko płaskie',
            LowEntry: 'Niskie wejście',
            LowFloor: 'Niska podłoga',
            NormalFloor: 'Normalna podłoga',
        },
        acDriver: 'Sterownik klimatyzacji',
        driverBerth: 'Koja kierowcy',
        wifi: 'Wi-Fi',
        cctv: 'Monitoring (CCTV)',
        internalCameras: 'Kamery wewnętrzne',
        externalCameras: 'Kamery zewnętrzne',
        retrocamera: 'Kamera widoku do tyłu',
        microphone: 'Mikrofon',
        cdPlayer: 'Odtwarzacz CD',
        dvdPlayer: 'Odtwarzacz DVD',
        usbPlayer: 'Odtwarzacz radiowy USB',
        heater: 'Ogrzewanie',
        preHeater: 'Podgrzewacz',
        kitchen: 'Kuchnia',
        coffeeMachine: 'Ekspres do kawy',
        refrigerator: 'Lodówka',
        toilet: 'Toaleta',
        safetyBelts: 'Pasy bezpieczeństwa',
        acPassengers: 'Klimatyzacja pasażera',
        curtains: 'Rolety przeciwsłoneczne',
        seatPocket: 'Siatka bagażowa',
        footRest: 'Podnóżek',
        handRest: 'Podłokietniki',
        seatsMonitor: 'Monitory siedzeń',
        seatsRadio: 'Radio samochodowe',
        seatsUsbPorts: 'USB do siedzeń',
        socket220: 'Gniazdo zasilania kierowcy 220 V',
        seatsSocket220: 'Gniazda zasilania foteli 220V',
        extendableSeats: 'Fotele z możliwością rozbudowy',
        recliningSeats: 'Fotele rozkładane',
        coffeeTables: 'Stoliki do siedzenia',
    },
    auction: {
        countdown: {
            startsIn: 'Zaczyna się między',
            endsIn: 'Kończy się między',
            days: 'g',
            hours: 'h',
            minutes: 'm',
            seconds: 's',
        },
        bail: {
            title: 'Wiąże wadium do udziału w aukcji',
            description: 'Aby wziąć udział w tej aukcji, należy wpłacić wadium za pomocą ważnej metody płatności.',
            amount: 'WADIUM',
            note: 'Wadium zostanie zwrócone po zakończeniu aukcji, jeśli nie znajdziesz się wśród osób, które zaoferowały najwyższą cenę.',
            subnote: 'Depozyty zabezpieczające nie są takie same dla wszystkich aktywów i mogą się różnić w zależności od bazy aukcji',
            error: 'Wystąpił błąd podczas przetwarzania transakcji, spróbuj ponownie później lub skontaktuj się z pomocą techniczną',
            submit: 'Wiążący depozyt zabezpieczający',
        },
        bid: {
            title: 'Zostań teraz oferentem z najwyższą ceną',
            description: 'Pamiętaj, że Twoja oferta jest wiążąca i jeśli nie zostanie przekroczona, zobowiązujesz się ją zapłacić.',
            yourNewOffer: 'Twoja nowa oferta',
            confirm: 'Potwierdź ofertę teraz',
            cancel: 'Anuluj',
            success: 'Oferta zaakceptowana!',
            total: 'Razem',
            outbidError: {
                title: 'Inny użytkownik przebił ofertę',
                description: 'Wybrana oferta została już przebita przez innego użytkownika, zamknij i zaktualizuj swoją ofertę.',
                close: 'Zamknij',
            },
            expiredError: {
                title: 'Aukcja została zakończona',
                description: 'Nie można już składać ofert.',
            },
        },
        bidButton: 'Złóż ofertę',
        auctionPanel: {
            reservePrice: 'Cena rezerwowa',
            startingPrice: 'Oferta początkowa',
            current: 'Aktualna',
            wonAuction: 'Aukcja rozstrzygnięta!',
            state: {
                Closed: 'Aukcja zakończona',
                Canceled: 'Aukcja anulowana',
                Draft: 'Wersja robocza aukcji',
            },
            maxBidder: 'Twoja oferta jest najlepsza!',
            outBid: 'Przebity!',
            negotiationInProgress: 'Negocjacje w toku',
            feeNotice: '+ prowizja ({0})',
            feeNoticeLink: 'jakie one są?',
            priceNotAvailable: 'NIE DOTYCZY',
            bidNotice: {
                reservePriceNotReached: {
                    base: 'Cena rezerwowa {0}',
                    notReached: 'jeszcze nie osiągnięta',
                },
                notScrapyard:
                    'Aukcja zarezerwowana dla wraków wpisanych do rejestru gospodarki odpadami w kat. 5 oraz operatorów o kodzie C.E.R. 16.01.04',
                sameCompany: {
                    Running: 'Pojazd należy do firmy',
                    base: 'Pojazd należy do firmy',
                },
                isAdmin: 'Nie możesz wziąć udziału, ponieważ Twój użytkownik ma rolę administratora',
            },
        },
        needSupport: 'Czy potrzebujesz wsparcia?',
        contactUs: 'Skontaktuj się z obsługą klienta Fleequid',
    },
    auctions: {
        page: {
            asset: {
                Bus: 'autobusy',
            },
            title: 'Odkryj wszystkie {title} na Fleequid',
            results: '{total} widoczne aukcje',
            emptyStateTitle: 'Brak wyników',
            emptyStateText: 'Zastosowane filtry nie dały żadnych wyników.',
            resetFilters: 'Usuń filtry',
        },
        filters: {
            applied: 'Zastosowane filtry',
            button: {
                label: 'Pręty filtrujące',
            },
            modal: {
                title: 'Aukcje filtrów',
                back: 'Powrót do wyników',
            },
            label: 'Filtry',
            class: 'Klasa',
            brand: 'Marka, model, wersja',
            brandFilter: 'Marka',
            brandFilterAnyOption: 'Wszystkie marki',
            modelFilter: 'Model',
            modelFilterAnyOption: 'Wszystkie modele',
            versionFilter: 'Wersja',
            versionFilterAnyOption: 'Wszystkie wersje',
            antiPollution: 'Regulacje zapobiegające zanieczyszczeniom',
            registration: 'Rejestracja',
            firstRegistrationYearFrom: 'Stan na (rok)',
            firstRegistrationYearTo: 'Do (rok)',
            firstRegistrationYearAnyOption: 'Dowolny rok',
            kilometers: 'Przebieg',
            kmFrom: 'Na dzień',
            kmTo: 'Do',
            kmAnyOption: 'Dowolny przebieg',
            scrapping: {
                title: 'Ograniczenia dotyczące rozbiórki',
                help: 'Jakie są ograniczenia rozbiórki?',
                all: 'Wszystkie pojazdy',
                requireScrapping: 'Z obowiązkowym złomowaniem',
                doNotRequireScrapping: 'Bez obowiązkowego złomowania',
            },
            fuel: 'Karmienie',
            empty: 'Bez zastosowanego filtra.',
        },
        appliedFilters: {
            firstRegistrationYearFrom: 'od: {year}',
            firstRegistrationYearTo: 'a: {year}',
            kmFrom: 'od: {km}',
            kmTo: 'a: {km}',
            reset: 'Usuń filtry',
            appliedFiltersCounter: '{count} aktywny filtr | {count} aktywne filtry',
        },
    },
    homepage: {
        link: 'Home',
        hero: {
            title: 'There are no heavy vehicles',
            subtitle: 'Join our online auctions and buy or sell your bus in a simple, fast and transparent way',
            buttonLabel: 'Participate in auctions',
        },
        whatIsFleequid: {
            title: 'What is Fleequid?',
            description: 'Fleequid is the new way to sell and buy vehicles at auction for your business',
            section: {
                simplicity: {
                    title: 'Simplicity',
                    description: 'A linear and intuitive process, which eliminates endless negotiations',
                },
                speed: {
                    title: 'Speed',
                    description: 'You can buy your new bus in 7 days',
                },
                transparency: {
                    title: 'Transparency',
                    description: 'Clear prices, detailed images, dedicated support',
                },
            },
        },
        vision: {
            caption: 'Our vision',
            title: 'We believe in the benefits of used vehicles. We want to help companies manage the buying and selling of company vehicles, saving time and resources, turning a problem into an opportunity.',
            highlight: 'Forget risks and uncertainties: with us, giving value to your assets becomes simple.',
        },
        howItWorks: {
            title: 'How it works',
            description: 'We strive to keep our platform simple and intuitive so you can easily manage your bus fleet',
            signUp: {
                title: 'Sign up for Fleequid',
                create: 'Create a {account} using your company details',
                account: 'new account',
                verify: 'Verify your details and {browse} the site',
                browse: 'start browsing',
            },
            join: {
                title: 'Participate in auctions',
                choose: 'Choose a {bus} from the list',
                bus: 'bus',
                add: 'Add a valid {payment} to start bidding',
                payment: 'payment method',
                follow: 'Follow the auction and {win}',
                win: 'win your favorite buses',
            },
            sell: {
                title: 'Sell your company buses',
                letUsKnow: 'Let us know all the details of the {bus}',
                bus: 'bus for sale',
                choose: 'Choose a {price} for your bus',
                price: 'minimum price', // TODO
                wait: 'Wait for the auction to end to {confirm}',
                confirm: 'confirm the sale',
            },
        },
        whyFleequid: {
            title: 'Why choose Fleequid?',
            description: 'We want to become your trusted partner in managing your fleet',
            section: {
                euReach: {
                    title: 'European reach',
                    description: 'Save time by avoiding undecided people and simplifying administrative and documentary procedures.',
                },
                unifiedBilling: {
                    title: 'Unified billing',
                    description: 'Enjoy the convenience of having an efficient platform and a single company name to invoice.',
                },
                easyAuctions: {
                    title: 'Easy and convenient auctions',
                    description: "Thanks to our pre- and post-purchase support service, participating in auctions will be child's play.",
                },
                operationalEfficiency: {
                    title: 'Operational efficiency',
                    description: 'Save time by avoiding the indecisive and simplifying paperwork.',
                },
                sell: {
                    title: 'Sell your vehicles',
                    description: 'Fleequid supports you in setting the sales price and creating the photo dossier.',
                    cta: 'Sell on Fleequid',
                },
            },
        },
        startToday: {
            title: 'Start today on Fleequid',
            description: 'View all available auctions and get ready for the best products',
            cta: 'Participate in auctions',
        },
    },
    faq: {
        title: 'Frequently asked questions',
        start: {
            base: 'How to get started',
            participate: {
                q: 'How can I participate in an online auction?',
                a: 'To join online auctions on Fleequid you need to sign up at {0} providing your business information and sending the required documents. Once you’ve done that, you can check out the pages with the ongoing auctions, and place one or more bids to compete for the purchase of a vehicle. Auctions are held electronically, and you can place bids until the auction closes. The auction ends when the user who submitted the highest bid awards the vehicle, as long as the bid is at least the reserve price, or if the seller accepts a bid that is lower than the reserve price. ',
                link: 'https://fleequid.com/en/signup',
            },
            reservePrice: {
                q: 'What is the reserve price?',
                a: 'The reserve price is the minimum amount that must be exceeded for the auction to be successful. If this amount is not reached during the auction, it’s up to the seller to decide whether they are willing to accept the best offer they received anyway. The reserve price is a higher amount than the starting bid: it is set by the seller before the start of the auction and will not be visible to the public, who only knows the highest bid made up to that moment.',
            },
            countries: {
                q: 'In which countries is it possible to buy and sell buses through Fleequid?',
                a: 'Fleequid operates in Italy, in the European Union and in several non-EU countries, complying with the regulations of each nation. If you have any doubts about the possibility of using Fleequid from your country, please contact our customer service at {0}.',
            },
            bureaucracy: {
                q: 'Who is responsible for property transfers, vehicle registration and every documentation aspect?',
                a: "Fleequid will communicate to seller and buyer how to handle documentation aspects and payments related to the property transfer, depending on the specifics of the deal (between Italian companies, EU, and non-EU) and on the platform's role in the sales process. However, registration of the vehicle in the country of destination is the responsibility of the buyer.",
            },
            toBeScraped: {
                q: 'What are vehicles intended for scrapping?',
                a: 'Vehicles intended for scrapping are those which, being no longer suitable for circulation and the transportation of people, must necessarily, according to current laws or regulation rules, undergo a recovery and partial reuse process of their components as spare parts. Participation in auctions for these vehicles is only permitted for dismantlers listed in the waste management register in category 5 and managers of the C.E.R. code 16.01.04. At the moment, the auctions for vehicles intended for scrapping are only available in Italy.',
            },
            vies: {
                q: 'What is Vies? Why is it mandatory to be included?',
                a: 'It’s a search engine (not a database) created by the European Commission to facilitate sales between companies in UE. It’s an electronic mean of validating VAT-identification numbers of economic operators registered in the European Union for cross border transactions on goods or services. For this reason, European companies that want to be on Fleequid must necessarily be present there.',
            },
        },
        sell: {
            base: 'How to sell',
            selling: {
                q: 'How can I start selling on Fleequid?',
                a: 'If you have a bus to sell, please contact our customer service by writing to {0} or fill out the form found at {1}, providing information about the vehicle you want to sell.  You also need to register your company as a seller on the platform.',
            },

            startingPrice: {
                q: 'How is the starting price defined?',
                a: 'The starting price is the minimum price for the vehicle above which potential buyers can place their bids or make counteroffers on the Marketplace. The starting bid is calculated by Fleequid based on its technical assessment of the vehicle and must be lower than the reserve price, which is instead set by the seller.',
            },
            delivery: {
                q: 'How is the vehicle delivered?',
                a: 'After receiving payment from the buyer, the seller must enable the buyer to collect the vehicle; the buyer must promptly proceed with the pickup, which must take place within ten days from the date Fleequid informs the seller of the completion of the de-registration procedures in the country of origin of the bus. For each day of delay beyond the specified timeline, Fleequid will impose a penalty.',
            },
            howLong: {
                q: 'How long does it take for the bus to be sold?',
                a: 'When a vehicle is uploaded to the platform, Fleequid shares with the seller the start and end dates of the auction and the information about pre-auction visibility; these dates are established by Fleequid based on the specifics of the vehicle to be sold. If the sale did not conclude successfully, Fleequid would still have the right to hold up to 3 consecutive auctions within a total of 30 days.',
            },
        },
        buy: {
            base: 'How to buy',
            inspection: {
                q: 'Is it possible to inspect vehicles before bidding?',
                a: "The seller can decide whether to offer the option of live inspection of the vehicle. If allowed, the seller will define a specific day for live visits and inspections of the vehicle, directly at the seller's location.",
            },
            claims: {
                q: 'How do you handle hidden defects and complaints?',
                a: 'Fleequid acts as an intermediary between seller and buyer and, as such, is not responsible for any faults, whether hidden or visible, defects, wear and tear, irregularities, unsuitability for use, as well as errors or inaccuracies in the declarations made by the seller. By posting their auction on Fleequid, the seller commits to transparency and to providing accurate information about the condition of the vehicle; at the same time, the buyer must commit to reporting any discrepancies in a timely manner (3 days for apparent defects) within one month from the pick-up for hidden defects of the vehicle. Fleequid is available to help buyers manage complaints and provides s-pecific conditions for their handling.',
            },
            paymentMethods: {
                q: 'What payment methods are accepted?',
                a: 'Fleequid accepts bank transfers and credit card payments. More specifically, the security deposit must be paid only by credit card; the purchase of the vehicle, the payment of commissions and any additional costs must be done via bank transfer. ',
            },
            howPaymentWorks: {
                q: 'How does payment work?',
                a: 'The user who wins the auction is required to pay an amount that includes the winning price, the selling fee for Fleequid and any other agreed-upon fees (see General Conditions for more details). The buyer must pay the amount due by bank transfer no later than three days after receiving the corresponding invoice. Partial payments are not allowed.',
            },
            deposit: {
                q: 'How does the security deposit work?',
                a: 'The security deposit is the sum of money that the user gives to Fleequid when placing a bid in the auction, as a guarantee of the seriousness of the offer itself. When the auction ends all deposits are released, except those of the first and second place winners. If the winner finalizes the purchase of the vehicle, by making the bank transfer for the agreed amount (no later than 3 days after receiving the corresponding invoice), both their deposit and that of the second bidder will be released. If the winner fails to make payment within the agreed time, they will lose both the bus and the security deposit. In this case, the second-place winner will automatically become the new winner of the auction and, by purchasing the vehicle by bank transfer, the security deposit will be returned.',
            },
        },
        contactUs:
            'We hope you have found all the answers to your questions on this page, but if not, we are always happy to help. Call us on {0}, or contact us by writing to {1}.',
    },
    footer: {
        cookiePolicy: {
            base: 'Polityka plików cookie',
            changeConsent: 'Zmiana zgody',
            revokeConsent: 'Cofnięcie zgody',
        },
        explore: 'Nawiguj',
        legal: 'Informacje prawne',
        support: 'Czy potrzebujesz wsparcia?',
        vat: 'NUMER VAT',
        backTop: 'Powrót do góry',
    },
    menu: {
        buy: 'Zakup',
        sell: 'Sprzedać',
        faq: 'F.A.Q.',
    },
    mktg: {
        whyFleequid: {
            title: 'Dlaczego Fleequid?',
            deal: {
                title: 'Uproszczone kupowanie i sprzedawanie',
                description: 'Platforma technologiczna zamiast negocjacji z niezdecydowanymi stronami',
            },
            processes: {
                title: 'Szybkie procesy',
                description: 'Fleequid skraca czas i oszczędza energię pracowników',
            },
            support: {
                title: 'Przejrzyste wsparcie',
                description: 'Wszystko jest jasne, od rejestracji po etapy dostawy',
            },
        },
    },
    notifications: {
        success: 'Sukces',
        error: 'Błąd',
        genericError: 'Wystąpił błąd. Spróbuj ponownie później.',
        tryAgain: 'Spróbuj ponownie',
        userOutBid: {
            base: 'Twoja oferta {prevBidAmount} za {subject} została przekroczona, nowa aktualna cena to {currentBidAmount}',
            inAuction: 'ta aukcja',
            linkedAuction: '{brand} {model} {version}',
        },
        userBailAuthorized: {
            inAuction: 'Zostałeś upoważniony do wzięcia udziału w tej aukcji',
            linkedAuction: 'Jesteś upoważniony do wzięcia udziału w tej aukcji {brand} {model}',
        },
        userMaxBidderRestored: {
            base: 'Twoja oferta {prevBidAmount} za {subject} jest najlepsza!',
            inAuction: 'ta aukcja',
            linkedAuction: '{brand} {model} {version}',
        },
    },
    privacy: {
        link: 'Polityka prywatności',
    },
    seo: {
        auction: {
            title: '{asset} {brand} {model} {version} {vin}',
            description:
                'Kup {brand} {model} {version}, {asset} używane na Fleequid.com: weź udział w aukcji B2B i licytuj {asset} marki {vin} model {brand} wersja {model} {version} marki {0}, modelu {0} i wersji {1}.',
        },
        auctions: {
            title: 'używane {year} na sprzedaż',
            description: 'Kup używane {year} online na Fleequid.com: łatwo weź udział w aukcjach B2B najlepszych używanych {0} na sprzedaż.',
        },
        cookiePolicy: {
            title: 'Polityka plików cookie',
            description:
                'Polityka plików cookie dla portalu Fleequid: przeczytaj, jak zarządzamy plikami cookie, których używamy, aby zapewnić wygodę przeglądania.',
        },
        homepage: {
            title: 'Używane autobusy na sprzedaż',
            description:
                'Kupuj lub sprzedawaj autobusy i pojazdy użytkowe na aukcjach: Dołącz do Fleequid i łatwo bierz udział w aukcjach B2B najlepszych pojazdów użytkowych.',
        },
        privacyPolicy: {
            title: 'Polityka prywatności',
            description:
                'Polityka prywatności dla portalu Fleequid: jasno wyjaśniamy, w jaki sposób obchodzimy się z danymi nawigacyjnymi i wszystkimi danymi użytkownika.',
        },
        signup: {
            title: 'Aukcje pojazdów: Rejestracja',
            description:
                'Zarejestruj się w portalu Fleequid, aby w najprostszy i najwygodniejszy sposób uczestniczyć w aukcjach nowych i używanych pojazdów użytkowych, autobusów i autokarów.',
            confirm: {
                title: 'Weryfikacja e-mail',
            },
        },
        termsAndConditions: {
            title: 'Zasady i warunki',
            description: 'Warunki sprzedaży i zakupu na Fleequid, internetowym portalu aukcyjnym i zakupowym dla pojazdów przemysłowych i autobusów.',
        },
        pricingSeller: {
            title: 'Prowizje sprzedającego',
            description:
                'Opłaty aukcyjne pobierane na Fleequid.com: szczegóły cenowe i specyfikacje dotyczące sposobu pobierania opłat za aukcje sprzedaży.',
        },
        pricingBuyer: {
            title: 'Prowizje dla kupujących',
            description:
                'Opłaty aukcyjne pobierane w serwisie Fleequid.com: szczegóły cenowe i specyfikacje dotyczące sposobu pobierania opłat związanych z aukcjami sprzedaży.',
        },
    },
    signup: {
        link: 'Rejestr',
        companyRegistration: 'Zarejestruj swoją firmę',
        subtitle: 'Wprowadź dane swojej firmy i wejdź do nowego świata ofert.',
        companyInfo: 'Dane firmy',
        companyDocuments: 'Dokumenty firmy',
        contactPerson: 'Dane kontaktowe firmy',
        accessCredentials: 'Poświadczenia dostępu',
        fleetHandling: 'Ruch floty',
        fields: {
            address: 'Adres',
            postCode: 'KOD POCZTOWY',
            city: 'Miasto',
            province: 'Województwo',
            businessName: 'Nazwa firmy',
            country: 'Kraj',
            pec: 'PEC',
            sdi: 'Kod SDI',
            vatId: 'VAT/VAT ID',
            companyRegistrationReportFile: 'Izba Handlowa Visura',
            firstname: 'Nazwa',
            lastname: 'Nazwisko',
            phone: 'Numer telefonu',
            type: {
                label: 'Typ operatora',
                Dealer: 'Przedsiębiorca',
                PeopleTransportOperator: 'Operator transportu pasażerskiego',
                Scrapyard: 'Wrak',
                Other: 'Inne',
            },
            email: 'E-mail',
            password: 'Hasło',
            confirmPassword: 'Potwierdzenie hasła',
            stats: {
                sales: 'Ile autobusów planujesz sprzedać w {0}?',
                purchase: 'Ile autobusów planujesz kupić w {size}?',
                unknown: 'Nie wiem',
            },
            consent: 'Konsensus',
            termsConditions: 'Przeczytałem i akceptuję {0} serwisu.',
            vexatiousClauses: 'Przeczytałem i akceptuję następujące klauzule:',
            privacy: 'Przeczytałem i akceptuję {email}.',
        },
        hint: {
            phone: {
                text: 'Format liczbowy',
                rule: 'Wprowadź numer telefonu w formacie +39 1234567890',
            },
        },
        validations: {
            requiredSDIOrPec: 'Wprowadź co najmniej jeden kod SDI lub adres PEC',
            companyRegistration: 'Wybrany plik jest zbyt duży. Maksymalny dozwolony rozmiar to {0} MB',
        },
        success: {
            title: 'Dziękujemy bardzo za ukończenie rejestracji!',
            p1: 'Twoje dane zostały pomyślnie odebrane.',
            p2: 'Wkrótce otrzymasz wiadomość e-mail z potwierdzeniem zawierającą link, który należy kliknąć, aby zweryfikować swój adres e-mail.',
            p3: 'Prosimy również o sprawdzenie folderu spam w skrzynce pocztowej, ponieważ ze względu na preferencje użytkownika wiadomość e-mail mogła zostać oznaczona jako wiadomości-śmieci.',
            p4: 'Zapraszamy do zapoznania się z aukcjami online i sprawdzenia, czy znajdują się na nich interesujące Cię pojazdy. Chcesz sprzedać swoje autobusy na następnej aukcji? Skontaktuj się z nami pod adresem {0}.',
            p5: 'Dziękujemy za wybranie nas!',
        },
        confirm: {
            success: {
                title: 'Twój e-mail został zweryfikowany!',
                p1: "Dziękujemy za potwierdzenie adresu e-mail {'@'}.",
                p2: 'Twoje konto jest teraz aktywne i możesz uzyskać dostęp do wszystkich funkcji naszego serwisu.',
                p3: 'Jeśli masz jakieś pytania lub potrzebujesz pomocy, skontaktuj się z naszym zespołem pomocy technicznej pod adresem {field}. Witamy na pokładzie!',
            },
            error: {
                title: 'Weryfikacja adresu e-mail nie powiodła się',
                p1: 'Przepraszamy, ale weryfikacja adresu e-mail nie powiodła się.',
                p2: 'Mógł wystąpić problem z linkiem potwierdzającym.',
                p3: 'Spróbuj ponownie, klikając link weryfikacyjny, który wysłaliśmy. Jeśli problem nie ustąpi, skontaktuj się z naszym zespołem pomocy technicznej pod numerem {field}.',
            },
            buyCta: 'Bierz udział w aukcjach',
            hpCta: 'Powrót do strony głównej',
        },
    },
    termsConditions: {
        link: 'Regulamin',
    },
    login: {
        email: 'E-mail',
        password: 'Hasło',
        formSubtitle: 'Wprowadź swoje dane uwierzytelniające Fleequid, aby uczestniczyć w aukcjach',
        forgotPassword: 'Zapomniałeś hasła?',
        or: 'Lub',
        unregistered: 'Nie masz konta Fleequid?',
        signupNow: 'Zarejestruj się teraz',
    },
    passwordReset: {
        request: {
            title: 'Zresetuj hasło',
            subTitle: 'Podaj swój adres e-mail, a wyślemy Ci wiadomość, aby zresetować hasło.',
            submitButton: 'Wyślij e-mail',
            onSuccessTitle: 'Żądanie zaakceptowane',
            onSuccessDescription: 'Otrzymasz wiadomość e-mail z procedurą ukończenia resetowania hasła.',
        },
        reset: {
            title: 'Reset hasła',
            description: 'Wprowadź nowe hasło. Po potwierdzeniu zmiany wszystkie aktywne sesje zostaną unieważnione.',
            newPassword: 'Nowe hasło',
            confirmNewPassword: 'Potwierdź nowe hasło',
            submitButton: 'Zmień hasło',
            updateSuccess: 'Hasło zostało pomyślnie zmienione',
            tokenExpired: 'Token resetowania hasła wygasł.',
            tokenInvalid: 'Token resetowania hasła jest nieprawidłowy.',
            startOverDescription: 'Wykonaj procedurę resetowania hasła ponownie, nowy token zostanie wygenerowany w tym samym czasie.',
            startOverButton: 'Spróbuj ponownie',
        },
    },
    validation: {
        msg: {
            characters: 'Jeden lub więcej nieprawidłowych znaków',
            email: 'E-mail musi być w formacie user{field}domain.ext',
            incomplete: '{count}niekompletny',
            invalid: '{count} nieważny',
            invalidBid: 'Wprowadzona kwota jest nieprawidłowa',
            missing: '{count} brak',
            max: 'Maksymalna długość {count} znaku | Maksymalna długość {count} znaku',
            min: 'Minimalna długość {count} znaku | Minimalna długość {field} znaku',
            length: 'Długość musi wynosić dokładnie {0} znaków | Długość musi wynosić dokładnie undefined znaków',
            passwordWeak: 'Hasło jest zbyt słabe',
            passwordMatch: 'Hasła nie są zgodne',
            phone: 'Wprowadź tylko cyfry, spacje i znak +',
            recaptcha: 'Błąd walidacji reCAPTCHA',
            required: 'undefined obowiązkowe',
        },
        hint: {
            password: {
                text: 'Zasady dotyczące skutecznego hasła',
                rules: {
                    length: 'Co najmniej 8 znaków',
                    uppercase: '1 wielka litera',
                    lowercase: '1 mała litera',
                    symbol: '1 znak specjalny',
                    number: '1 cyfra',
                },
            },
        },
    },
    profile: {
        title: 'Twój profil',
        companyData: {
            readOnlyDisclaimer: 'Dane firmy są tylko do odczytu, aby poprosić o zmianę, skontaktuj się z nami pod adresem undefined.',
        },
        userData: {
            saveChanges: 'Potwierdź nowe dane',
            cancelChanges: 'Anuluj zmiany',
            updateSuccess: 'Dane kontaktowe firmy zostały pomyślnie zapisane',
        },
        changePassword: {
            title: 'Zmień hasło',
            description: 'Zmiana hasła spowoduje unieważnienie wszystkich innych aktywnych sesji z wyjątkiem bieżącej.',
            currentPassword: 'Bieżące hasło',
            newPassword: 'Nowe hasło',
            confirmNewPassword: 'Potwierdź nowe hasło',
            submitButton: 'Potwierdź nowe hasło',
            updateSuccess: 'Hasło zostało pomyślnie zmienione',
        },
        changeEmail: {
            title: 'Zmień adres e-mail',
            description:
                'Otrzymasz wiadomość e-mail z linkiem do potwierdzenia nowego adresu e-mail. Zmiana adresu e-mail spowoduje unieważnienie wszystkich aktywnych sesji.',
            password: 'Aktualne hasło',
            email: 'E-mail',
            submitButton: 'Potwierdź nowy adres e-mail',
            updateSuccess: 'Pomyślnie zmieniono adres e-mail',
        },
    },
    sessionExpired: {
        title: 'Twoja sesja wygasła',
        description: 'Twoja sesja została unieważniona, ponieważ Twoje dane logowania zostały zmienione. Zaloguj się przy użyciu nowych poświadczeń.',
        close: 'Kontynuuj',
    },
};

export default pl;
